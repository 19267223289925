import { Tag, Typography } from '@quickbit/qb-design-system';
import { CodeBlock } from '../../CodeBlock';
import { InlineCode } from '../../InlineCode';

const createMigrationFlowOrderExample = `{
  "amount": 100,
  "currency": "EUR",
  "callback_url": "https://my-domain.com/api/quickbit-callback",
  "redirect_url": "https://my-domain.com/api/quickbit-redirect",
  "use_migration_flow": true, // <-- This flag toggles on the migration flow
  "country_code": "GB", // <-- This will streamline the identification process
  "email": "gimli.mcgloin@lotr.com" // <-- This identifies returning customers
}`;

const createMigrationFlowOrderResponseExample = `{
  "amount": 100,
  "currency": "EUR",
  "uuid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "created_at": "2023-04-12T06:23:07.558Z",
  "checkout_url": "https://app.quickbit.com/top-up?receiveOrderPreviewId=afc65186-b966-4f11-98bc-b06ae85ac255",
  "reference_id": null,
  "status": "PENDING",
  "creation_amount": 100,
  "email": "gimli.mcgloin@lotr.com"
}`;

const createMigrationFlowOrderWithCardDetailsExample = `{
  "amount": 100,
  "currency": "EUR",
  "callback_url": "https://my-domain.com/api/quickbit-callback",
  "redirect_url": "https://my-domain.com/api/quickbit-redirect",
  "use_migration_flow": true,
  "country_code": "GB", 
  "email": "gimli.mcgloin@lotr.com",
  "credit_card_data": {
    "card_holder": "Gimli McGloin",
    "encrypted_credit_card_number": "HKh94zsDkYLcVSvI+v57zN6nxC6kxoDOO4XyPZR6f/3EuE2s89QF7D+SphnnCBYpzMgLt/pPzh8duVC1E7NJKrZEfY8Zh5CtCGS0c+rYuKK8pX9RiNtAgyk/EenmWlD3CVynf8JPKNOK6K3cKaOeEv/eqDewyMSOtIpvXrMn3HX2/0pEci/q+Hm5ExnqagfwThpiNLM62xrhQkwzdJ/JDSpkPFgu1UfWhiSfRz81yvZ2/FId39eMTeIHZkruBcOcWFLQwqGXKp2l39ccdj5zLIWEe1aEX4Td2RfK2GTKf/jvwLCP/jk25DdDxDfsbPui/niMXBilUoTqDR0XlSjdrw==",
    "encrypted_cvv": "FtfvtGEVkOGRaInGI99tYnZLudTlKjGlAsBbX3cA2baSwaKwZOfE2CTUX26hWhgvTQCYhYViqKjWqA8MqRnndu9iLdAMTldswh4K4VjVoG2VPd5z7W3IVxlskWmyHrKViH1l1R+/8T+xOMAvDl5qNQRh0cXGYcqPrCVfw/gGl9mEBf+quczECQmf9EFhF9rJ7X1sN7Tx7caSPp1Tu8nZfL4xg6j4UzjBWsdtXxftO9Cj5bJH9Gh51dCNLrQJnobSJXBTsOjRXeD3HaUrsktUjDMG0i4uUj3qgLxGFsbNywF/4s/mZNLaInNNR+oI5eKS8DmM2rfNVQMtDoUcuvxOXw==",
    "expiry_month": "01", // <-- This needs to be 2 characters long 01-12
    "expiry_year": "2030" // <-- This needs to be 4 characters long
  }
}`;

const pythonEncryptExample = `import requests
from base64 import b64encode
from cryptography.hazmat.primitives import hashes, serialization
from cryptography.hazmat.primitives.asymmetric import padding, rsa

def encrypt_credit_card_details(credit_card_number, cvv):
    response = requests.get(
        "https://qb-pay.quickbit.com/api/pay/v1/encryption",
        headers={
          "Content-Type": "application/json",
          "x-api-key": "your-api-key-goes-here" # <-- Add your API key here
        }
    )
    public_key_string = response.json()["public_key"]
    public_key = serialization.load_pem_public_key(
        public_key_string.encode()
    )

    encrypted_credit_card_number = public_key.encrypt(
        credit_card_number.encode(),
        padding.PKCS1v15(),
    )
    encrypted_cvv = public_key.encrypt(
        cvv.encode(),
        padding.PKCS1v15(),
    )
    return (
        b64encode(encrypted_credit_card_number).decode("utf-8"),
        b64encode(encrypted_cvv).decode("utf-8")
    )
`;

const nodeEncryptExample = `import forge from 'node-forge';

const encryptCardData = async (creditCardNumber: string, cvv: string) => {
  const keyResponse = await fetch(
    'https://qb-pay.quickbit.com/api/pay/v1/encryption',
    { headers: {
      "Content-Type": "application/json",
      "x-api-key": "your-api-key-goes-here" // <-- Add your API key here
    }}
  ).json();

  const publicKey = forge.pki.publicKeyFromPem(keyResponse.public_key);
  const encryptedCreditCardNumber = publicKey.encrypt(
    data.creditCardNumber,
    'RSAES-PKCS1-V1_5'
  );
  const encryptedCvv = publicKey.encrypt(data.cvv, 'RSAES-PKCS1-V1_5');
  return [
    forge.util.encode64(encryptedCreditCardNumber),
    forge.util.encode64(encryptedCvv),
  ];
};
`;

export const MigrationFlow = () => {
  return (
    <>
      <Typography variant="heading1">Migration flow</Typography>

      <Typography variant="body">
        The migration flow is a way to increase Quickbit Pay conversion and let
        your customers purchase crypto from within your system. It works in a
        similar fashion to the regular Quickbit Pay checkout flow, but instead
        of the customer being sent to the Quickbit Pay checkout page, they are
        directed to the Quickbit App where they can create an account to
        purchase and complete their order, as well as do other cryptocurrency
        related things.
      </Typography>
      <br />
      <Typography variant="body">
        To begin the Migration checkout flow, an order must be created in the
        Quickbit Pay system in the same way as for a regular Quickbit Pay order.
        However, it is done by setting the{' '}
        <InlineCode>use_migration_flow</InlineCode> flag to{' '}
        <InlineCode>true</InlineCode> in the request body.
      </Typography>
      <CodeBlock language="json" code={createMigrationFlowOrderExample} />
      <Typography variant="body">
        There are two additional <b>optional</b> inputs that can be sent in the
        request body:
      </Typography>
      <ul>
        <li>
          <InlineCode>country_code</InlineCode> - By providing the country code
          for customers, the Migration flow can immediately trigger the correct
          identity verification process and create a better customer experience.
        </li>
        <li>
          <InlineCode>email</InlineCode> - By providing the email for the
          customers, the Migration flow can skip the identification process
          entirely for returning customers.
        </li>
      </ul>
      <Typography variant="body">
        The response is also of the same format as a regular Quickbit Pay order,
        with the exception of the checkout URL. This URL instead directs the
        customer to the Quickbit app. See the example below.
      </Typography>
      <CodeBlock
        language="json"
        code={createMigrationFlowOrderResponseExample}
      />
      <br></br>
      <Typography variant="body">
        To ensure the best possible customer experience and to avoid unexpected
        changes in crypto exchange rates, we recommend that you persist the
        order ID and the checkout URL upon creation of the order. For instance,
        a customer can accidentally close the browser or refresh the page after
        they have purchased the crypto, but before sending it to the address
        associated with the order. In such a scenario they will have a wallet
        with crypto in it but the order would still be incomplete. By persisting
        the order information, the customer can be redirected to the same
        transaction flow.
      </Typography>
      <br />
      <Tag value="Note" iconName="info" variant="primary" size="large" />
      <Typography variant="body">
        The migration flow will create orders in Bitcoin.
      </Typography>
      <br></br>

      <Typography variant="heading2">The Quickbit app</Typography>
      <Typography variant="body">
        When the app is opened through the checkout URL in the response, it will
        be preconfigured to assist the customer in finishing their order as
        seamlessly as possible. For instance, if the customer lacks the balance
        required in their Quickbit account, they will first be directed to
        purchase the correct amount of crypto before sending it to the address
        associated with the order. The customer can, however, interact with
        other parts of the app if they choose to do so.
      </Typography>
      <br></br>
      <Typography variant="body">
        There are multiple ways for a customer to identify themselves in the
        app. For Swedish, Norwegian and Finnish customers, specific national
        identification methods are available (such as the Swedish BankID). For
        all other customers, Onfido is used, which is a login method that
        verifies customers&apos; identities by having them upload ID documents
        such as passports.
      </Typography>
      <br></br>
      <Typography variant="body">
        The Onfido login method is a bit cumbersome and time consuming. Because
        of this, customers using this method will be asked to supply an email
        address and password after a successful login. For future login
        attempts, these two fields can then be used instead to create a smoother
        experience. If the email address of the customer is entered as part of
        the above mentioned request body, the email/password login method will
        be pre-selected for the customer.
      </Typography>
      <br></br>
      <Tag value="Note" iconName="info" variant="primary" size="large" />
      <Typography variant="body">
        Due to limitations in the Onfido test environment, only the United
        Kingdom can be selected as the country code for testing purposes.
        However, the document used can be from any country (and does not need to
        be real). To cause some more confusion, the United Kingdom is not an
        available country in production for the Quickbit app.
      </Typography>
      <br></br>
      <Typography variant="heading2">
        Already have credit card details collected for your customers?
      </Typography>
      <Typography variant="body">
        If you already have credit card details collected for your customers,
        you can streamline the migration flow even further by adding encrypted
        credit card details to the order creation request. See the example
        below.
      </Typography>
      <CodeBlock
        language="json"
        code={createMigrationFlowOrderWithCardDetailsExample}
      />
      <br />
      <Typography variant="subtitle">How to encrypt card details</Typography>
      <Typography variant="body">
        To encrypt the credit card details, you need to use a public encryption
        key from Quickbit. This can be accessed using the{' '}
        <InlineCode>GET /api/pay/v1/encryption</InlineCode> endpoint. This needs
        to be used to encrypt the credit card number and CVV.
      </Typography>
      <br />
      <Typography variant="body">
        Under the hood, Quickbit uses encryption parameters from{' '}
        <a
          href="https://worldline.com/en/home/main-navigation/solutions/merchants/payment-orchestration.html"
          target="_blank"
          rel="noreferrer"
        >
          PaymentIQ
        </a>
        , our payment service provider. PaymentIQ is fully PCI compliant, and
        Quickbit does not have access to their private key. This means that
        Quickbit cannot decrypt the credit card details, but will instead
        forward the encrypted card details to PaymentIQ where they will be
        processed for the purchase.
      </Typography>
      <br />
      <Typography variant="body">
        The encryption algorithm is <b>RSA</b> with <b>PKCS1</b> padding. The
        resulting encrypted data should be base64 encoded. Below are examples of
        how to encrypt the credit card details using Python and Typescript.
      </Typography>
      <CodeBlock language="python" code={pythonEncryptExample} />
      <CodeBlock language="ts" code={nodeEncryptExample} />
    </>
  );
};
