/** @jsxImportSource @emotion/react */
import { useIntl } from 'react-intl';
import { IllustrationWithText } from 'components';

export const NotFound = () => {
  const { formatMessage } = useIntl();

  return (
    <IllustrationWithText
      illustration="unplugged"
      heading={formatMessage({ id: 'page.notFound.heading' })}
      subheading={formatMessage({ id: 'page.notFound.subHeading' })}
    />
  );
};
