import { CryptoCurrency } from '@quickbit/qb-design-system';
import { useState } from 'react';
import { useAsync, useAsyncFn } from 'react-use';
import { useOrderId } from 'context';
import { useCountdown } from 'hooks';
import { createPreview, PreviewResponse, updatePreview } from '../api';

const PREVIEW_REFRESH_RATE_SECONDS = 15 * 60;

export const usePreview = (currency?: CryptoCurrency) => {
  const orderId = useOrderId();
  const [preview, setPreview] = useState<PreviewResponse>();

  const [{ error: updateError }, refreshPreview] = useAsyncFn(async () => {
    const updated = await updatePreview(orderId);
    setPreview(updated);
  }, [orderId]);

  const { start: startTimer, timer } = useCountdown({
    from: PREVIEW_REFRESH_RATE_SECONDS,
    restart: true,
    onEnd: refreshPreview,
  });

  const { error: previewError, loading } = useAsync(async () => {
    if (!currency) return;
    const previewResponse = await createPreview(orderId, currency);
    setPreview(previewResponse);
    startTimer();
  }, [currency]);

  return {
    preview,
    timer,
    error: previewError ? previewError : updateError,
    loading,
  };
};
