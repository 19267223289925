/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Typography,
  useTheme,
  List,
  CryptoCurrency,
  Button,
  ListItem,
  Copy,
  removeScaleFromMoney,
  Loader,
} from '@quickbit/qb-design-system';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useOrderId } from 'context';
import { useOrderDetails, usePoll } from 'hooks';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { fetchOrderStatus, OrderStatusResponse } from 'pages/api';
import { Wallet } from 'types';
import { isMobileDevice, redirectBasedOnStatus } from 'utils';
import { ErrorPage } from '../../components';
import { LineWithText, QRCode, WalletSelect } from './components';
import { usePreview } from './hooks';
import { createQRCodeAddressURL } from './utils';

export const Home = () => {
  const { spacing, palette, shape } = useTheme();
  const { formatMessage } = useIntl();
  const formatCurrency = useFormatCurrency();
  const [selectedWallet, setSelectedWallet] = useState<Wallet>();

  const { order, error } = useOrderDetails();

  const {
    preview,
    timer,
    error: previewError,
    loading: previewLoading,
  } = usePreview(selectedWallet?.currency);

  const orderId = useOrderId();
  const navigate = useNavigate();

  const checkStatus = useCallback(
    ({ status }: OrderStatusResponse) =>
      redirectBasedOnStatus(status, navigate) || null,
    [navigate]
  );

  useEffect(() => {
    if (order) {
      checkStatus({ status: order.order.status });
    }
  }, [order, checkStatus]);

  const poll = useCallback(() => fetchOrderStatus(orderId), [orderId]);

  const {
    start,
    error: pollingError,
    isPolling,
  } = usePoll({
    onPoll: checkStatus,
    poll,
  });

  useEffect(() => {
    if (!isPolling) start();
  }, [isPolling, start]);

  if (error || previewError || pollingError)
    return (
      <ErrorPage
        error={error ?? previewError ?? pollingError!}
        tryAgainAction={() => navigate(0)}
      />
    );

  const wallets = order?.wallets;
  return (
    <>
      <div css={{ marginBottom: spacing.xs }}>
        <div
          css={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            marginBottom: spacing.xxxs,
            minHeight: spacing.s,
          }}
        >
          {order && !order.order.hideFiatAmount && (
            <Typography variant="subtitle">
              <FormattedMessage
                id="page.home.title"
                values={{
                  amount:
                    order &&
                    formatCurrency({
                      amount: order?.order.amount,
                      currencyCode: order?.order.currency,
                      scale: 0,
                    }),
                }}
              />
            </Typography>
          )}
          <div css={{ gridColumn: '2 / span 1', justifySelf: 'end' }}>
            {preview && timer}
          </div>
        </div>
        <Typography variant="body">
          <FormattedMessage
            id={!selectedWallet ? 'page.home.subtitle1' : 'page.home.subtitle2'}
          />
        </Typography>
      </div>
      {previewLoading && <Loader />}
      {!previewLoading && preview && (
        <>
          <div
            css={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: spacing.xxs,
            }}
          >
            <div
              css={{
                display: 'flex',
                border: `1px solid ${palette.grey.x100}`,
                padding: spacing.xxxs,
                borderRadius: shape.borderRadius,
              }}
            >
              <QRCode
                value={createQRCodeAddressURL(
                  preview.cryptoAmount,
                  preview.destinationAddress
                )}
                currency={preview.cryptoAmount.currencyCode as CryptoCurrency}
              />
            </div>
          </div>
          {order && !order.order.hideQbBrand && (
            <>
              <LineWithText text={formatMessage({ id: 'page.home.or' })} />
              <div css={{ marginTop: spacing.xxs, marginBottom: spacing.xs }}>
                <Button
                  text={formatMessage({ id: 'page.home.useApp' })}
                  onClick={() => {
                    window.open(
                      isMobileDevice()
                        ? `quickbit://app.quickbit.com/_send/${preview.cryptoAmount.currencyCode}/destination/${preview.destinationAddress}`
                        : `${process.env.REACT_APP_WEB_APP_BASE_URL}/${preview.cryptoAmount.currencyCode}/send/preview?receiveOrderPreviewId=${preview.uuid}`,
                      '_blank'
                    );
                  }}
                />
              </div>
            </>
          )}
        </>
      )}
      {wallets && (
        <List>
          <WalletSelect
            wallets={wallets}
            value={selectedWallet?.currency}
            onChange={(wallet) => setSelectedWallet(wallet)}
            showDropdown={true}
          />
          {preview && selectedWallet ? (
            <>
              <ListItem
                title={formatMessage({ id: 'address' })}
                subtitle={preview.destinationAddress}
                action={<Copy textToCopy={preview.destinationAddress} />}
              />
              <ListItem
                title={formatMessage({ id: 'page.home.amount' })}
                subtitle={formatCurrency(preview.cryptoAmount)}
                action={
                  <Copy
                    textToCopy={removeScaleFromMoney(
                      preview.cryptoAmount
                    ).toString()}
                  />
                }
              />
            </>
          ) : (
            <></>
          )}
        </List>
      )}
    </>
  );
};
