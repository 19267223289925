/** @jsxImportSource @emotion/react */
import { createStyles } from '@quickbit/qb-design-system';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useMount } from 'react-use';
import { IllustrationWithText, ErrorPage } from 'components';
import { useOrderId } from 'context';
import { usePoll } from 'hooks';
import { fetchOrderStatus, OrderStatusResponse } from 'pages/api';
import { OrderStatus } from 'types';
import { redirectBasedOnStatus } from 'utils';

export const ProcessingInternal = () => {
  const orderId = useOrderId();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const styles = useStyles();

  const checkStatus = useCallback(
    ({ status }: OrderStatusResponse) => {
      if (status === OrderStatus.PROCESSING_INTERNAL) {
        return null;
      }
      return redirectBasedOnStatus(status, navigate);
    },
    [navigate]
  );

  const poll = useCallback(() => fetchOrderStatus(orderId), [orderId]);

  const { start, error } = usePoll({
    onPoll: checkStatus,
    poll,
  });

  useMount(() => start());

  if (error)
    return <ErrorPage error={error} tryAgainAction={() => navigate('/')} />;

  return (
    <div css={styles.main}>
      <IllustrationWithText
        illustration="updateApp"
        heading={formatMessage({
          id: 'page.processingInternal.heading',
        })}
        subheading={formatMessage({
          id: 'page.processingInternal.subHeading',
        })}
      />
    </div>
  );
};

const useStyles = createStyles(() => ({
  main: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
}));
