import { OrderStatus } from 'types';

export const redirectBasedOnStatus = (
  status: OrderStatus,
  navigate: (path: string) => void
): boolean => {
  if (status === OrderStatus.PROCESSING_INTERNAL) {
    navigate('/processing-internal');
    return true;
  }
  if (status === OrderStatus.PROCESSING_EXTERNAL) {
    navigate('/processing-external');
    return true;
  }
  if (
    status === OrderStatus.COMPLETE ||
    status === OrderStatus.CRYPTO_RECEIVED ||
    status === OrderStatus.CRYPTO_SOLD
  ) {
    navigate('/success');
    return true;
  }
  if (
    status === OrderStatus.INVALID_AMOUNT ||
    status === OrderStatus.REFUND_CRYPTO_RECEIVED
  ) {
    navigate('/invalid-amount');
    return true;
  }
  if (
    status === OrderStatus.REFUND_ADDRESS_SUBMITTED ||
    status === OrderStatus.REFUNDING
  ) {
    navigate('/refunding');
    return true;
  }
  if (status === OrderStatus.REFUNDED) {
    navigate('/refunded');
    return true;
  }
  if (status === OrderStatus.CANCELLED) {
    navigate('/cancelled');
    return true;
  }
  return false;
};
