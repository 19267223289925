import {
  getCurrencyName,
  MoneyScaled,
  removeScaleFromMoney,
} from '@quickbit/qb-design-system';

export const createQRCodeAddressURL = (
  amount: MoneyScaled,
  destinationAddress: string
) => {
  const addressUrl = `${getCurrencyName(amount.currencyCode)
    .toLowerCase()
    .replaceAll(' ', '')}:${destinationAddress}?amount=${removeScaleFromMoney(
    amount
  )}`;
  return addressUrl;
};
