/** @jsxImportSource @emotion/react */
import { Tag, Typography, useTheme } from '@quickbit/qb-design-system';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import QbPayFlow from '../../images/qb-pay-flow.png';
import QbPay from '../../images/qb-pay.png';
import { CodeBlock } from '../CodeBlock';
import { InlineCode } from '../InlineCode';
import { MagnifiedImage } from '../MagnifiedImage';

const apiKeyExample = `{
  ...
  "Content-Type": "application/json",
  "X-Api-Key": "IpnY9w96eWqiWwY9jCbt3y7cC49SOTHre7nYpIIoAoSVQ-V-17E-yWjjCPXiOT0Z",
  ...
}`;

const iframeExample = `<iframe
  src="https://qb-pay.quickbit.com?order-id=3fa85f64-5717-4562-b3fc-2c963f66afa6"
  allow="clipboard-write; clipboard-read"
/>`;

const createOrderExample = `{
  "amount": 100,
  "currency": "EUR",
  "callback_url": "https://my-domain.com/api/quickbit-callback",
  "redirect_url": "https://my-domain.com/api/quickbit-redirect"
}`;

const createOrderResponseExample = `{
  "amount": 100,
  "currency": "EUR",
  "uuid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "created_at": "2023-04-12T06:23:07.558Z",
  "checkout_url": "https://qb-pay.quickbit.com?order-id=3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "reference_id": null,
  "status": "PENDING",
  "creation_amount": 100,
  "errors": null,
  "email": null
}`;

const callbackExample = `{
  "order_id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "type": "NOTICED",
  "reference_id": "9013AA"
}`;

const getOrderResponseExample = `{
  "amount": 100,
  "currency": "EUR",
  "uuid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "created_at": "2023-04-12T06:23:07.558Z",
  "checkout_url": "https://qb-pay.quickbit.com?order-id=3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "reference_id": "9013AA",
  "status": "COMPLETED",
  "creation_amount": 100,
  "errors": [],
  "email": null,
  "card_details": {
    "masked_card_number": "535583******1234",
    "account_holder": "Roger Quickbitsson"
  },
  "ssn_information": {
    "ssn": "198001011234",
    "country": "SE"
  }
}`;

const cardExampleWithout3ds = `Card number: 5555 5555 5555 4444
Name: <Any name>
Expiry date: <Any future date>
CVV: <Any number>`;

const cardExampleWith3ds = `Card number: 4444 3333 2222 1111
Name: <Any name>
Expiry date: <Any future date>
CVV: <Any number>`;

const errorsList = `From Quickbit:
BLOCKCHAIN_CANCELLED - The crypto transaction was cancelled on the blockchain
PIQ_DEPOSIT_UNEXPECTED_ERROR - An error occurred when creating a buy request (using encrypted card details in the migration flow)
PIQ_DEPOSIT_REQUEST_FAILED - The request didn't cause an exception, but failed when creating a buy request (using encrypted card details in the migration flow)
PIQ_DEPOSIT_UNEXPECTED_TX_STATE - The deposit somehow ended up in a state that wasn't expected after creating a buy request (using encrypted card details in the migration flow)
ORDER_TIMED_OUT - The order timed out according to the merchant configuration regarding how orders live.

From PaymentIQ:
ERR_SYSTEM_ERROR
ERR_FAILED_TO_CONNECT
ERR_DECLINED_BAD_REQUEST
ERR_DECLINED_FRAUD
ERR_DECLINED_NO_FUNDS
ERR_DECLINED_ACCOUNT_SUSPENDED
ERR_DECLINED_OTHER_REASON
ERR_DECLINED_CONTACT_SUPPORT
ERR_DECLINED_CONFIG_ERROR
ERR_NOT_AUTHENTICATED
ERR_INVALID_RESPONSE
ERR_DECLINED_REQ_BLOCKED
ERR_PSP_OUT_OF_SERVICE
ERR_DECLINED_NOT_AUTHORIZED
ERR_RESPONSE_CODE_UNKNOWN
ERR_PSP_ACCOUNT_USED_BY_OTHER_USER
ERR_PSP_ACCOUNT_NOT_USED
ERR_TOO_MANY_PSP_ACCOUNTS
ERR_DECLINED_DUPLICATE_TX_ID
ERR_DECLINED_INVALID_ACCOUNT_NUMBER
ERR_MERCHANT_OUT_OF_SERVICE
ERR_DECLINED_LIMIT_OVERDRAWN
ERR_MERCHANT_RESPONSE_CODE_UNKNOWN
ERR_DECLINED_NO_PROVIDER_FOUND
ERR_DECLINED_PROVIDER_ACCOUNT_CONFIG_ERROR
ERR_MERCHANT_INVALID_RESPONSE
ERR_ABOVE_LIMIT
ERR_BAD_REQUEST
ERR_BELOW_LIMIT
ERR_DECLINED_BANK_REFUSAL
ERR_DECLINED_CARD_EXPIRED
ERR_DECLINED_CVV_FAIL
ERR_DECLINED_EMAIL_BLACKLIST
ERR_DECLINED_FRAUDRULE_PSP
ERR_DECLINED_INVALID_AMOUNT
ERR_DECLINED_INVALID_ISSUER
ERR_DECLINED_ISSUER_UNAVAILABLE
ERR_DECLINED_LOST_OR_STOLEN
ERR_DECLINED_RESTRICTED_CARD
ERR_DECLINED_RESTRICTED_TERRITORY
ERR_DECLINED_VELOCITYRULE_PSP
ERR_INVALID_PARAMETER
ERR_IO_EXCEPTION
ERR_RESPONSIBLE_GAMING_LIMIT
ERR_USER_ACCOUNT_BLOCKED
ERR_USER_SESSION_INVALID
ERR_DECLINED_3D_VALIDATION_FAILED
ERR_DECLINED_3D_EXPIRED
ERR_DECLINED_3D_AUTH_TECH_FAIL
ERR_DECLINED_3D_AUTH_REJECTED
ERR_DECLINED_3D_AUTH_USER_FAIL
ERR_VAULTIQ_OUT_OF_SERVICE
ERR_DECLINED_IP_BLOCKED
ERR_DECLINED_BIN_BLOCKED
ERR_VAULTIQ_UNKNOWN_ACCOUNT
ERR_DECLINED_KYC_BLOCK
ERR_DECLINED_KYC_USER_UNDER_AGE
ERR_DECLINED_KYC_CHECK_FAILED
ERR_DECLINED_BIC_BLOCK
ERR_DECLINED_EXPIRED
ERR_DECLINED_REPEAT_CANCELLED
ERR_DECLINED_CURRENCY_NOT_SUPPORTED
ERR_DECLINED_FRAUD_SCORE_THRESHOLD_EXCEEDED
ERR_DECLINED_MERCHANT_NOT_FOUND
ERR_DECLINED_MERCHANT_NOT_ENABLED
ERR_DECLINED_PROVIDER_NOT_ENABLED
ERR_DECLINED_UNDER_MAINTENANCE
ERR_NO_REFERRAL_TX_FOUND
ERR_DECLINE_TX_NOT_FOUND
ERR_DECLINE_COUNTRY_NOT_SUPPORTED
ERR_DECLINED_NOT_SUPPORTED_PAYMENT_METHOD_FRAUD
ERR_DECLINED_FRAUD_PROVIDER_ACCOUNT_CONFIG_ERROR
ERR_DECLINED_GENERAL_BLOCK
ERR_DECLINED_3D_PROVIDER_ACCOUNT_CONFIG_ERROR
ERR_DECLINE_USER_NOT_FOUND
ERR_DECLINE_NO_AMOUNTS
ERR_UNSUPPORTED_CHARACTERS
ERR_MESSAGE_SENT_TO_KAFKA_FAILED
ERR_DECLINED_3D_INIT_SYSTEM_ERROR
ERR_DECLINED_ID_VERIFICATION_FAILED
ERR_DECLINED_SCA_REQUIRED_BY_ISSUER
ERR_DECLINED_MISSING_REDIRECT_URL
ERR_DECLINED_SCHEME_TOKEN_EXPIRED
ERR_DECLINED_INVALID_SCHEME_TOKEN
ERR_DECLINED_MERCHANT_NO_FUNDS
ERR_DECLINED_NAME_MISMATCH
ERR_DECLINED_WAITING_KYC_VERIFICATION
ERR_DECLINED_3D_AUTH_SYSTEM_ERROR
ERR_DECLINED_DO_NOT_TRY_AGAIN
ERR_CANCELLED_BY_USER
ERR_CANCELLED_BY_MERCHANT
ERR_CANCELLED_BY_PSP
ERR_NOT_SUPPORTED`;

const getAllOrdersResponseExample = `{
  "orders": [
    {
      "amount": 100,
      "currency": "EUR",
      "uuid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      "created_at": "2023-04-12T06:23:07.558Z",
      "checkout_url": null,
      "reference_id": "9013AA",
      "status": "COMPLETED",
      "creation_amount": 100,
      "errors": [],
      "email": "roger.quickbitsson@quickbit.com",
      "card_details": {
        "masked_card_number": "535583******1234",
        "account_holder": "Roger Quickbitsson"
      },
      "ssn_information": {
        "ssn": "198001011234",
        "country": "SE"
      },
      "callback_url": "https://my-domain.com/api/quickbit-callback",
    },
  ],
  "aggregated_data": {
      "order_count": 1
  }
}`;

const ordersSearchRequestBodyExample = `// Goes inside request body
{
  "pagination": {
    "page": 1,
    "size": 1
  },
  "filters": {
    "created_from": "2024-04-25T18:30:00.000Z",
    "created_to": "2024-04-30T18:30:00.000Z"
  }
}`;

export const Integration = () => {
  const { spacing } = useTheme();

  return (
    <>
      <Typography variant="heading1">Integrating with Quickbit Pay</Typography>
      <Typography variant="body">
        This page will go through the different steps required to integrate with
        the Quickbit Pay product. This includes describing the flow for
        completing a customer&apos;s purchase, as well as how the endpoints from
        the Quickbit Pay API should be used.
      </Typography>
      <br></br>
      <Typography variant="body">
        For a detailed specification of the API endpoints, see{' '}
        <Link to="/docs/endpoints">Endpoints</Link>.
      </Typography>
      <br></br>
      <Typography variant="heading2">The Quickbit Pay checkout flow</Typography>
      <Typography variant="body">
        Quickbit Pay is responsible for the payment part of your website&apos;s
        checkout flow. This means that your website is responsible for making
        sure the correct items are being purchased. After initiating the
        Quickbit Pay checkout flow, we will take over and make sure the correct
        amount of money is spent by the customer. We will then notify you about
        whether the order was successful or not.
      </Typography>
      <br></br>
      <Typography variant="body">
        Below we outline the steps of this flow.
      </Typography>
      <MagnifiedImage
        src={QbPayFlow}
        alt="The Quickbit Pay checkout page"
        width={2243}
        height={871}
      />
      <br></br>
      <Typography variant="heading2">Authenticating your requests</Typography>
      <Typography variant="body">
        In order for Quickbit Pay to know which merchant is responsible for
        which order, we need to authenticate the requests sent to our system.
        This is done using API keys. When you were onboarded as a merchant, you
        should have been provided with such a key.
      </Typography>
      <br></br>
      <Typography variant="body">
        Each request sent to the Quickbit Pay system needs to provide the
        aforementioned API key in its headers. Specifically, the{' '}
        <InlineCode>X-Api-Key</InlineCode> header should be set to your API key.
        See an example below.
      </Typography>
      <CodeBlock language="json" code={apiKeyExample} />
      <br></br>
      <Typography variant="heading2">The Quickbit Pay environments</Typography>
      <Typography variant="body">
        There are two environments available for Quickbit Pay; a test
        environment and a production environment. The production environment
        should be used in your production environment, and the test environment
        should be used when developing your integration with us. Each
        environment requires a separate API key.
      </Typography>
      <br></br>
      <Typography variant="body">
        The test environment differs only slightly from the production
        environment. No real settlements are performed, and it is possible to
        simulate order status changes from the Quickbit Pay backoffice. This
        simulation was added to remove the need for you to send money to test
        your integration.
      </Typography>
      <br></br>
      <Typography variant="body">
        The test environment is available at{' '}
        <a href="https://qb-pay.test.quickbit.com">
          https://qb-pay.test.quickbit.com
        </a>{' '}
        and the production environment is available at{' '}
        <a href="https://qb-pay.quickbit.com">https://qb-pay.quickbit.com</a>.
        This means that, for instance, a request for creating an order
        (described in more detail below) can be sent to{' '}
        <a href="https://qb-pay.test.quickbit.com/api/pay/v1/order">
          https://qb-pay.test.quickbit.com/api/pay/v1/order
        </a>
        .
      </Typography>
      <br></br>
      <Tag value="Note" iconName="info" variant="primary" size="large" />
      <Typography variant="body">
        Even though the test environment does not pay out any settlements or
        require real money to be used, it still uses real blockchain addresses
        for sending crypto. This means that if you were to send crypto to an
        address generated with Quickbit Pay, real money will be sent to that
        address.
      </Typography>
      <br></br>
      <div id="create-order"></div>
      <Typography variant="heading2">Creating an order</Typography>
      <Typography variant="body">
        To begin the Quickbit Pay checkout flow, an order must be created in the
        Quickbit Pay system to keep track of the amount required and the status
        of the payment. This is done using one of our API endpoints - the{' '}
        <InlineCode>POST /api/pay/v1/order</InlineCode> endpoint.
      </Typography>
      <br></br>
      <Typography variant="body">
        The endpoint for creating orders requires a JSON body sent with it. This
        body should contain the amount as well as the currency of the order. You
        can optionally provide a URL where we can notify you about updates to
        the order. More about this URL can be read in the{' '}
        <HashLink to="/docs/integration#callback-events">
          Callback events
        </HashLink>{' '}
        section. Finally, the body can optionally contain two additional
        parameters: a reference ID and a redirect URL. The reference ID can be
        added for you to keep track of the order in your system; it will be
        included in callbacks about the order. The redirect URL is a way to
        automatically redirect customers to a link of your choice once an order
        has been completed.
      </Typography>
      <br></br>
      <Typography variant="body">
        As an example, imagine your website sells shoes and a customer wants to
        purchase two pairs of shoes for a price of 100 EUR. You would then
        create an order with the following request body:
      </Typography>
      <CodeBlock language="json" code={createOrderExample} />
      <Typography variant="body">
        You would then receive a response with the created order. This order
        will contain information about the amount (and the creation amount),
        when the order was created, its status (which will be{' '}
        <InlineCode>PENDING</InlineCode> for newly created orders), as well as
        containing a checkout URL that will be described more in the next
        section. An example of an order response can be seen below.
      </Typography>
      <CodeBlock language="json" code={createOrderResponseExample} />
      <Typography variant="body">
        The <InlineCode>uuid</InlineCode> is the unique identifier of your
        order. When notifying you about order changes, this identifier will be
        used. Make sure to save this ID in your system.
      </Typography>
      <br></br>
      <Typography variant="body">
        The difference between an order&apos;s amount and creation amount is
        dependent on Quickbit&apos;s fee configuration for your integration. If
        a customer fee is set (i.e. a fee that is paid by your customers), the
        amount will include that fee, whereas the creation amount will be equal
        to the amount entered when sending the request. The difference between
        these two amounts is therefore the customer fee for the order.
      </Typography>
      <br></br>
      <Tag value="Note" iconName="info" variant="primary" size="large" />
      <Typography variant="body">
        The <InlineCode>callback_url</InlineCode> in the request body should be
        replaced with the URL specifically created in your system.
      </Typography>
      <br></br>
      <Tag value="Note" iconName="info" variant="primary" size="large" />
      <Typography variant="body">
        Only certain currencies are supported when creating orders. See the{' '}
        <Link to="/docs/endpoints">Endpoints</Link> page for exact details.
      </Typography>
      <br></br>
      <Typography variant="heading2">Rendering the checkout page</Typography>
      <Typography variant="body">
        As is shown above, the data returned from creating an order contains a
        checkout URL. This URL leads to a checkout page that the customer will
        use to send money. It allows the customer to select which cryptocurrency
        they want to use, and an address is then generated for them to send
        money to. This address is connected to the order, so the Quickbit Pay
        system will use it to determine that an order is processing or
        completed, and make sure the money gets sent your way.
      </Typography>
      <br></br>
      <div
        css={{
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'block',
          textAlign: 'center',
        }}
      >
        <img
          css={{
            width: '300px',
            marginBottom: spacing.xxxs,
          }}
          src={QbPay}
          alt="The Quickbit Pay checkout page"
        />
        <Typography variant="captionMedium">
          An example of the checkout page.
        </Typography>
      </div>
      <br></br>
      <Typography variant="body">
        As can be seen above, it is important to the checkout flow that the
        customer is redirected to the Quickbit Pay checkout page. This can be
        done in two ways: by using an <InlineCode>iframe</InlineCode> (the
        recommended approach) or by opening the page in a new window. The former
        of these two is recommended because it allows the customer to stay
        inside your checkout ecosystem through the entire process. However, both
        options will work similarly. For more information about{' '}
        <InlineCode>iframes</InlineCode>, see{' '}
        <a
          href="https://www.w3schools.com/tags/tag_iframe.ASP"
          target="_blank"
          rel="noreferrer"
        >
          this link
        </a>
        .
      </Typography>
      <br></br>
      <Tag value="Note" iconName="info" variant="primary" size="large" />
      <Typography variant="body">
        For the rendered checkout page to work correctly in an{' '}
        <InlineCode>iframe</InlineCode>, make sure to allow the{' '}
        <InlineCode>clipboard-write</InlineCode> and{' '}
        <InlineCode>clipboard-read</InlineCode> options, as seen below.
      </Typography>
      <CodeBlock language="jsx" code={iframeExample} />
      <br></br>
      <div id="callback-events" />
      <Typography variant="heading2">Callback events</Typography>
      <Typography variant="body">
        The Quickbit Pay system will notify you on the specified{' '}
        <InlineCode>callback_url</InlineCode> when the order is either cancelled
        or completed. How and when this is done depends on how the customer pays
        for their purchase. If a customer uses the Quickbit app to complete
        their order, the callback event will be created as soon as they have
        sent the correct amount in the app. If they, on the other hand, use an
        external cryptocurrency wallet, the transaction will be sent out on the
        blockchain. Quickbit Pay monitors the blockchain for updates and will
        notify you with a callback event once we recognise the transaction on
        it. Therefore, it takes a little bit longer for orders to be completed
        if the customer sends crypto from outside the Quickbit custodial
        environment.
      </Typography>
      <br></br>
      <Typography variant="body">
        The callback event will contain a body with the following properties:
        the order ID, the callback type, as well as the reference ID if that was
        provided. An example body is shown below.
      </Typography>
      <CodeBlock language="json" code={callbackExample} />
      <Typography variant="body">
        The order ID is added for you to determine which order we are notifying
        you about. This will be the same ID as the <InlineCode>uuid</InlineCode>{' '}
        returned in the response when creating orders. Similarly, the reference
        ID is added (if you provided one) for the same reason.
      </Typography>
      <br></br>
      <Typography variant="body">
        Finally, the type of the callback determines what we are notifying you
        about. The following values are possible:
      </Typography>
      <ul>
        <li>
          <InlineCode>NOTICED</InlineCode> - This means we have noticed the
          transaction being sent on the blockchain. This callback will only be
          sent if the customer pays using an external wallet, i.e. not using the
          Quickbit app. In most cases, this means the money will correctly be
          sent to the Quickbit system (to then be settled into your bank
          account). This callback event can therefore be used to continue your
          checkout process and thereby reduce the time the customer has to wait
          on the blockchain. Do note, however, that there is a slight risk of
          the transaction not reaching its destination.
        </li>
        <li>
          <InlineCode>COMPLETED</InlineCode> - This means the money has reached
          the Quickbit system and will be sent to you in the next settlement.
          The remainder of your checkout can proceed without risk, such as
          sending a confirmation email or giving the product to the customer. If
          you specified a <InlineCode>redirect_url</InlineCode> when creating
          the order, the checkout page will redirect to that URL when this event
          is sent.
        </li>
        <li>
          <InlineCode>CANCELLED</InlineCode> - This means the order has been
          cancelled by the Quickbit Pay system. This can, for instance, be used
          to remove the reservation of a product in your system. It is possible
          to configure when orders should be cancelled using the Quickbit
          backoffice. More about this can be read{' '}
          <HashLink to="/docs/backoffice">here</HashLink>.
        </li>
        <li>
          <InlineCode>REFUNDED</InlineCode> - This means the order has been
          refunded by the Quickbit Pay system. This happens if the customer sent
          an invalid amount and provides an address for Quickbit Pay to send
          back the money to. It can be treated the same as the order being
          cancelled. More about this can be read in the{' '}
          <HashLink to="/docs/integration#refunds">Refunds</HashLink> section.
        </li>
        <li>
          <InlineCode>ERROR_OCCURRED</InlineCode> - This means the order
          received an error. This could depend on many different things, such as
          the customer using an invalid payment method or something going wrong
          on the blockchain. It should however be noted that an error occurring
          does not mean that the order has failed. It is possible for an order
          to have an error and still be completed successfully. The errors can
          be found in the <InlineCode>errors</InlineCode> field of the order.
          They can also be found in the Quickbit backoffice. More about this can
          be read in the <HashLink to="/docs/backoffice">Backoffice</HashLink>{' '}
          section.
        </li>
      </ul>
      <Tag value="Note" iconName="info" variant="primary" size="large" />
      <Typography variant="body">
        When a callback is sent from the Quickbit Pay system, the order status
        will be the same status as the callback type unless it is the{' '}
        <InlineCode>ERROR_OCCURRED</InlineCode> type.
      </Typography>
      <br></br>
      <Typography variant="subtitle">
        Simulating callbacks for testing purposes
      </Typography>
      <Typography variant="body">
        Since real blockchain addresses are used even in the test environment,
        it can be difficult to test the Quickbit Pay checkout flow. For that
        reason, we have added the possibility of simulating status changes for
        orders into the different callback states. This can be done in two ways:
        either by using the{' '}
        <InlineCode>
          {'POST /api/pay/v1/order/{order_id}/simulate-callback'}
        </InlineCode>{' '}
        simulation endpoint, or by using the backoffice to update the order.
        That is, you can send requests that will cause you to receive callbacks
        for an order, or you can use an interface to do it. This will also
        update the order, meaning that the Quickbit Pay checkout page will be
        updated depending on which status the order got instead.
      </Typography>
      <br></br>
      <Typography variant="body">
        The simulation endpoint and backoffice actions are only available in the
        Quickbit Pay test environment.
      </Typography>
      <br></br>
      <Typography variant="heading2">Fetching an order</Typography>
      <Typography variant="body">
        In case an order needs to be retrieved in the future you can do so using
        the <InlineCode>{'GET /api/pay/v1/order/{order_id}'}</InlineCode>{' '}
        endpoint. As can be seen, the endpoint requires the order ID. This is
        yet another reason why it is important to store the{' '}
        <InlineCode>uuid</InlineCode> returned when creating orders.
      </Typography>
      <br></br>
      <Typography variant="body">
        The endpoint for fetching orders will return the same type of response
        as the endpoint for creating orders. However, it may also include card
        details and or SSN information if access to this information is part of
        your contract with Quickbit, and if this information is available. An
        example can be seen below.
      </Typography>
      <CodeBlock language="json" code={getOrderResponseExample} />
      <br></br>
      <Typography variant="heading2">Fetching all orders</Typography>
      <Typography variant="body">
        If you wish to get all orders created from a certain time, you can do so
        using the <InlineCode>POST /api/pay/v1/orders/search</InlineCode>{' '}
        endpoint.
      </Typography>
      <br />
      <Typography variant="body">
        The endpoint for fetching all orders supports pagination and filtering
        which means you can pass these options in a JSON body in request. To
        make the response paginated, provide a <InlineCode>page</InlineCode> and{' '}
        <InlineCode>size</InlineCode> in a <InlineCode>pagination</InlineCode>{' '}
        object inside JSON body in request. The endpoint supports filtering the
        response based on creation time. In case you only need orders for a
        certain time period, provide a time in <InlineCode>filters</InlineCode>{' '}
        object inside JSON body in request via the{' '}
        <InlineCode>created_from</InlineCode> and{' '}
        <InlineCode>created_to</InlineCode> property. Alternatively you can pass
        only <InlineCode>created_from</InlineCode> to get all orders from a
        specific date and time, or <InlineCode>created_to</InlineCode> to get
        all orders up to a specific date and time. There are other filters that
        you can include. For more detailed information, see{' '}
        <Link to="/docs/endpoints">Endpoints</Link>. Below is an example of all
        the supported options which you can send in request body
      </Typography>
      <CodeBlock language="json" code={ordersSearchRequestBodyExample} />
      <br />
      <Typography variant="body">
        The response is an object with <InlineCode>orders</InlineCode> list
        containing all orders created by merchants under the same platform if a
        platform is making the request or all orders of a single merchant if a
        merchant is making the request. The{' '}
        <InlineCode>aggregated_data</InlineCode> contains information about the
        total number of orders. An example response can be found below.
      </Typography>
      <CodeBlock language="json" code={getAllOrdersResponseExample} />
      <br />
      <Typography variant="heading2">
        Paying with the Quickbit app in the test environment
      </Typography>
      <Typography variant="body">
        As can be seen in the checkout page, it is possible to pay for orders
        using the Quickbit app. This is also the payment method used when using
        the <Link to="/docs/integration/migration-flow">Migration Flow</Link>.
        This app uses identity verification methods, and allows purchasing of
        cryptocurrencies using a credit card. Because of this, some setup is
        required to make it work in the test environment (where real identity
        verification is not possible and real credit cards do not work).
      </Typography>
      <br />
      <Typography variant="body">
        There are multiple identity verification methods available, depending on
        the country used for logging in:
      </Typography>
      <ul>
        <li>
          <Typography variant="body">
            The method with the least setup required is Onfido (used for the
            United Kingdom), simply follow the login steps to setup an account.
            The document used for verification does not matter in the test
            environment. To make the account persist, this method will require
            you to set an email and password for future login attempts.
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            A method requiring some setup is BankID (used for Sweden). This
            method requires a Swedish Test BankID which can be set up{' '}
            <a href="https://demo.bankid.com/">here</a>. Once setup, simply
            login using the personal number you generated, as well as the BankID
            app. Note that a test BankID app and production BankID app cannot be
            installed on the same device.
          </Typography>
        </li>
      </ul>
      <br />
      <Typography variant="body">
        Card details that can be used in the test environment are available{' '}
        <a href="https://developer.worldpay.com/products/access/card-payments/testing/">
          here
        </a>
        . Cards may not work with all setups, so we recommend using any of the
        following two cards:
      </Typography>
      <br />
      <Typography variant="body">Without 3DS:</Typography>
      <CodeBlock language="txt" code={cardExampleWithout3ds} />
      <Typography variant="body">With 3DS:</Typography>
      <CodeBlock language="txt" code={cardExampleWith3ds} />
      <Tag value="Note" iconName="info" variant="primary" size="large" />
      <Typography variant="body">
        Some specific values can be used to trigger errors in the card purchase
        flows. These values are available{' '}
        <a href="https://developer.worldpay.com/products/access/card-payments/testing/">
          here
        </a>
        .
      </Typography>
      <br />
      <div id="refunds" />
      <Typography variant="heading2">Refunds</Typography>
      <Typography variant="body">
        Quickbit Pay checks that the correct amount is sent by the customer when
        purchasing your goods and services. In case the customer sends the wrong
        amount (or the amount is outside the bounds when using the{' '}
        <Link to="/docs/start#tolerance">Tolerance feature</Link>), the user can
        no longer complete the order and is asked to provide an address for
        Quickbit to refund the money to. This is all done in the checkout page,
        meaning no interaction is required from your side. The customer will be
        refunded in the same cryptocurrency they used to pay with.
      </Typography>
      <br></br>
      <Typography variant="body">
        Once the customer has been refunded, we will send a callback event to
        you letting you know that this has happened. Since a refunded order
        cannot be completed, you can utilize this as a way of telling your
        customer to recreate the order.
      </Typography>
      <br></br>
      <Typography variant="body">
        Currently, the Quickbit Pay system does not support manual refunds, i.e.
        the cases where a customer paid the correct amount but wishes to return
        their product in accordance with your refund policies. This means that a
        customer purchasing your goods or services using cryptocurrencies will
        need to be refunded in fiat using any of your traditional systems.
      </Typography>
      <br></br>
      <Typography variant="body">
        Manual refunds using Quickbit Pay will be supported in the future.
      </Typography>
      <br></br>
      <Typography variant="heading2">Payouts</Typography>
      <Typography variant="body">
        Payouts enable you as a merchant to send money in a cryptocurrency to
        your customer. This feature is currently in development and will be
        explained in more detail once it is available.
      </Typography>
      <br></br>
      <Typography variant="heading2">Errors</Typography>
      <Typography variant="body">
        As mentioned in the{' '}
        <HashLink to="/docs/integration#callback-events">
          Callback events
        </HashLink>{' '}
        section, errors can occur when processing an order. Some of these errors
        are propagated directly from{' '}
        <a
          href="https://worldline.com/en/home/main-navigation/solutions/merchants/payment-orchestration.html"
          target="_blank"
          rel="noreferrer"
        >
          PaymentIQ
        </a>
        , our payment service provider, if the user pays using the Quickbit app.
        Others are generated by Quickbit. Below is a list of the possible error
        codes. For the errors generated by Quickbit, there are also matching
        descriptions.
        <CodeBlock code={errorsList} language="txt" />
      </Typography>
    </>
  );
};
