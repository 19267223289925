/** @jsxImportSource @emotion/react */
import { useIntl } from 'react-intl';
import { useMount } from 'react-use';
import { IllustrationWithText } from 'components';
import { useOrder } from 'context';
import { inIframe } from 'utils';

export const Cancelled = () => {
  const { formatMessage } = useIntl();
  const { failureRedirectUrl } = useOrder();

  useMount(() => {
    sessionStorage.clear();
    if (failureRedirectUrl) {
      setTimeout(() => {
        const windowLocation = inIframe()
          ? window.parent.location
          : window.location;
        windowLocation.replace(failureRedirectUrl);
      }, 2000);
    }
  });

  return (
    <IllustrationWithText
      illustration="unplugged"
      heading={formatMessage({ id: 'page.cancelled.heading' })}
      subheading={
        failureRedirectUrl
          ? formatMessage({ id: 'redirect.backToMerchant' })
          : formatMessage({ id: 'page.cancelled.subHeading' })
      }
    />
  );
};
