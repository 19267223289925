import { CryptoCurrency, MoneyScaled } from '@quickbit/qb-design-system';
import { fetchAPI } from 'api';

export interface PreviewResponse {
  uuid: string;
  createdAt: Date;
  cryptoAmount: MoneyScaled;
  destinationAddress: string;
}

export const createPreview = (
  orderId: string,
  cryptoCurrency: CryptoCurrency
) => {
  return fetchAPI<PreviewResponse>(`v1/order/${orderId}/create-preview`, {
    method: 'POST',
    body: {
      cryptoCurrency,
    },
  });
};

export const updatePreview = (orderId: string) => {
  return fetchAPI<PreviewResponse>(`v1/order/${orderId}/update-preview`, {
    method: 'PUT',
  });
};
