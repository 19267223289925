/** @jsxImportSource @emotion/react */
import { Typography, createStyles, useTheme } from '@quickbit/qb-design-system';
import { useIntl } from 'react-intl';
import { useMount } from 'react-use';
import { IllustrationWithText } from 'components';
import { useOrder } from 'context';
import { inIframe } from 'utils';

export const Success = () => {
  const { formatMessage } = useIntl();
  const styles = useStyles();
  const { amount, currency, redirectUrl, hideFiatAmount } = useOrder();
  const { spacing, palette } = useTheme();

  useMount(() => {
    sessionStorage.clear();
    if (redirectUrl) {
      setTimeout(() => {
        const windowLocation = inIframe()
          ? window.parent.location
          : window.location;
        windowLocation.replace(redirectUrl);
      }, 2000);
    }
  });

  return (
    <div css={styles.main}>
      <IllustrationWithText
        illustration="transferSuccess"
        heading={formatMessage({
          id: 'page.success.heading',
        })}
        subheading={
          hideFiatAmount
            ? formatMessage({
                id: 'page.success.subHeading.hideFiatAmount',
              })
            : formatMessage(
                {
                  id: 'page.success.subHeading',
                },
                {
                  amount,
                  currency,
                }
              )
        }
      />
      {redirectUrl && (
        <div
          css={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: spacing.xxxs,
          }}
        >
          <Typography variant="body" color={palette.grey.x500}>
            {formatMessage({
              id: 'redirect.backToMerchant',
            })}
          </Typography>
        </div>
      )}
    </div>
  );
};

const useStyles = createStyles(({ spacing, palette }) => ({
  main: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
  toApp: {
    border: `1px solid ${palette.grey.x200}`,
    background: palette.grey.x100,
    marginTop: spacing.xxs,
    width: '100%',
    borderRadius: spacing.xxxs,
    padding: `${spacing.xs} ${spacing.xxs}`,
    textAlign: 'center',
  },
  appBtn: {
    marginTop: spacing.xs,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  QRImg: { marginTop: spacing.xs },
}));
