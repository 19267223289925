/** @jsxImportSource @emotion/react */
import { CryptoCurrency, useTheme, iconMap } from '@quickbit/qb-design-system';
import { QRCodeSVG } from 'qrcode.react';
import { encodeSvg } from '../utils';

interface Props {
  value: string;
  currency: CryptoCurrency;
}

export const QRCode = ({ value, currency }: Props) => {
  const { spacing } = useTheme();
  return (
    <QRCodeSVG
      value={value}
      imageSettings={{
        src: encodeSvg(iconMap[currency]),
        height: parseInt(spacing.xs),
        width: parseInt(spacing.xs),
        excavate: true,
      }}
    />
  );
};
