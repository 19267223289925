/** @jsxImportSource @emotion/react */
import ReactImageMagnify from 'react-image-magnify';

interface Props {
  src: string;
  alt: string;
  width: number;
  height: number;
}

export const MagnifiedImage = ({ src, alt, width, height }: Props) => {
  return (
    <div css={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
      <div
        css={{
          display: 'grid',
          width: '80%',
        }}
      >
        <ReactImageMagnify
          smallImage={{
            alt: alt,
            isFluidWidth: true,
            src: src,
          }}
          largeImage={{
            src: src,
            width: width,
            height: height,
          }}
          enlargedImagePosition="over"
        />
      </div>
    </div>
  );
};
