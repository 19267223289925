import { useCookies as reactUseCookies } from 'react-cookie';
import { Cookie, CookieSetOptions } from 'universal-cookie';

export const getCookieExpirationDate = (expDays = 1): Date => {
  const date = new Date();
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
  return date;
};

type UseCookiesReturnType = [
  Record<string, Cookie>,
  (value: Record<string, Cookie>, options?: CookieSetOptions) => void,
  (options?: CookieSetOptions) => void
];

/**
 *
 * @param cookieName specifies the cookie name your component depend on or that should trigger a re-render.
 *
 * `cookieName` will be used to set and remove cookies as well.
 * @returns {Array} UseCookiesReturnType
 */
export const useCookies = (cookieName: string): UseCookiesReturnType => {
  const [allCookies, set, remove] = reactUseCookies([cookieName]);
  const cookie = allCookies[cookieName];

  const defaultOptions = {
    path: '/',
    expires: getCookieExpirationDate(),
  };

  const setCookie = (
    value: Record<string, Cookie>,
    options?: CookieSetOptions
  ) => {
    set(cookieName, value, { ...defaultOptions, ...options });
  };

  const removeCookie = (options?: CookieSetOptions) => {
    remove(cookieName, options);
  };

  return [cookie, setCookie, removeCookie];
};
