import Cookies from 'js-cookie';
import { createContext, useContext, ReactNode } from 'react';
import { getOrderIdFromSearchParams } from './utils';

export const ORDER_COOKIE_NAME = 'qbPay_orderId';

const getOrderId = (): string => {
  const searchParamsOrderId = getOrderIdFromSearchParams();
  if (searchParamsOrderId) {
    Cookies.set(ORDER_COOKIE_NAME, searchParamsOrderId);
    return searchParamsOrderId;
  }
  const cookieOrderId = Cookies.get(ORDER_COOKIE_NAME);
  if (cookieOrderId) return cookieOrderId;
  throw Error('No order-id found in either Cookies or search params');
};

const OrderIdContext = createContext<string>('');

export const useOrderId: () => string = () => {
  return useContext(OrderIdContext);
};

export const OrderIdProvider = ({ children }: { children: ReactNode }) => {
  return (
    <OrderIdContext.Provider value={getOrderId()}>
      {children}
    </OrderIdContext.Provider>
  );
};
