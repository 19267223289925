/** @jsxImportSource @emotion/react */
import { createStyles } from '@quickbit/qb-design-system';
import { ReactNode, FunctionComponent } from 'react';

interface Props {
  children: ReactNode;
}

export const Layout: FunctionComponent<Props> = ({ children }) => {
  const styles = useStyles();

  return (
    <div css={styles.container}>
      <main css={styles.content}>{children}</main>
    </div>
  );
};

const useStyles = createStyles(({ spacing, breakpoints, palette, sizing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${spacing.s} ${spacing.xxs} ${spacing.m} ${spacing.xxs}`,
    background: palette.white,
    [breakpoints.laptopSmall]: {
      alignItems: 'center',
      paddingBottom: '0px',
    },
  },
  content: {
    flex: '1 1 auto',
    position: 'relative',
    [breakpoints.laptopSmall]: {
      maxWidth: sizing.large,
      width: '100%',
      paddingBottom: `calc(2 * ${spacing.m})`,
    },
  },
  footer: {
    width: '100%',
    maxWidth: sizing.medium,
    margin: 'auto',
    [breakpoints.laptopSmall]: {
      flex: 1,
      paddingLeft: spacing.m,
      paddingRight: spacing.m,
    },
  },
}));
