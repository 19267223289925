/** @jsxImportSource @emotion/react */
import { Icon, Tag, Typography } from '@quickbit/qb-design-system';
import { Link } from 'react-router-dom';
import BackofficeAccounting from '../images/backoffice-accounting.png';
import BackofficeOrders from '../images/backoffice-orders.png';
import { InlineCode } from './InlineCode';
import { MagnifiedImage } from './MagnifiedImage';

export const Backoffice = () => {
  return (
    <>
      <Typography variant="heading1">Backoffice</Typography>
      <Typography variant="body">
        The Quickbit Pay backoffice is a tool that comes with the Quickbit Pay
        product that allows you to get more information about the orders
        processed through the system. In it, you will have access to various
        tabs that show both aggregate and detailed information about orders. It
        also allows you to perform actions on orders using the interface. This
        can be useful for your accounting or support purposes.
      </Typography>
      <br></br>

      <Typography variant="heading2">Accessing the backoffice</Typography>
      <Typography variant="body">
        When you are added as a merchant in our system, we will require an email
        address for the initial backoffice user. This email will then be sent a
        link for setting a password, and can then be used to login to the
        backoffice. Once in the backoffice, it is possible to create more users
        with different permissions (these permissions determine which tabs a
        user can interact with). It is also possible for Quickbit to create more
        users for you if required.
      </Typography>
      <br></br>
      <Typography variant="body">
        The backoffice can be accessed at{' '}
        <a href="https://merchant-backoffice.test.quickbit.com">
          https://merchant-backoffice.test.quickbit.com
        </a>{' '}
        or{' '}
        <a href="https://merchant-backoffice.quickbit.com">
          https://merchant-backoffice.quickbit.com
        </a>
        , depending on which environment you are using.
      </Typography>
      <br></br>

      <Typography variant="heading2">The accounting tab</Typography>
      <MagnifiedImage
        src={BackofficeAccounting}
        alt="The Quickbit Pay accounting backoffice tab"
        width={1511}
        height={744}
      />
      <br></br>
      <Typography variant="body">
        The accounting tab in the Quickbit Pay backoffice is used for showing
        all completed orders. It details when they were completed, as well as
        the amount of the orders and the fees Quickbit take from each order. It
        also aggregates this data for an easier overview. It is possible to
        filter on date ranges to limit the number of records shown.
      </Typography>
      <br></br>
      <Typography variant="body">
        All data visible in this tab can be downloaded as a CSV file by using
        the <InlineCode>Create report</InlineCode> button.
      </Typography>
      <br></br>

      <Typography variant="heading2">The order tab</Typography>
      <MagnifiedImage
        src={BackofficeOrders}
        alt="The Quickbit Pay order backoffice tab"
        width={1511}
        height={744}
      />
      <br></br>
      <Typography variant="body">
        The order tab in the Quickbit Pay backoffice is used for showing all
        your orders. It is quite similar to the accounting tab, but shows all
        orders instead of just the completed ones. It also shows which status
        the order has, as well as provides actions that can be performed on the
        orders. This tab can be filtered on date ranges as well as statuses. It
        is also possible to search for orders with specific order IDs or
        reference IDs.
      </Typography>
      <br></br>
      <Typography variant="subtitle">Actions</Typography>
      <Typography variant="body">
        The resend callback action can be used to retrigger callbacks from the
        Quickbit Pay system to yours. This can, for instance, be useful when
        communication issues have occurred, if there has been a network outage,
        or if there has been an error on your end while processing the callback.
        Instead of the order ending up in limbo, this action can be used to
        restore the proper state of the order on your end. This does not affect
        the order in any way in our system.
      </Typography>
      <br></br>
      <Typography variant="body">
        The view errors action can be used to show any errors that have occurred
        for an order. This can happen for many reasons, such as invalid payment
        methods being used by a customer or something wrong happening on the
        blockchain. It should however be noted that an error occurring does not
        mean that the order has failed. It is possible for an order to have an
        error and still be completed successfully. Any order with an error on it
        will also have a{' '}
        <Icon name="error" className={{ display: 'inline-block' }} /> icon next
        to the Actions dropdown.
      </Typography>
      <br></br>
      <Typography variant="body">
        If used in the test environment, each order contains actions for
        simulating callbacks and order state changes. This can be used during
        integration to make sure the Quickbit Pay flow works as expected,
        without you having to send money over the blockchain.
      </Typography>
      <br></br>
      <Typography variant="heading2">Config</Typography>
      <Typography variant="body">
        In the Config tab you can set the bounds for the{' '}
        <Link to="/docs/start#tolerance">Tolerance feature</Link>. If used in
        the test environment, it is also possible to toggle the merchant&apos;s
        status between active and blocked. This is especially useful as a
        platform, because the merchants you create are blocked by default.
        Otherwise, it can be used to simulate a merchant being blocked and how
        that affects your flow.
      </Typography>
      <br></br>
      <Typography variant="body">
        Aside from the above mentioned configuration, it is also possible to set
        when orders should be cancelled in this tab. By default, orders are
        never cancelled, but if this setting is toggled on, it is possible for
        you to decide after how many minutes an order should be cancelled if it
        has not been completed. If a number is set, the order will be cancelled
        and a callback will be sent roughly after that much time has passed
        since the order was created. It is also possible to toggle if orders
        should be cancelled if they have an error.
      </Typography>
      <br />
      <Tag value="Note" iconName="info" variant="primary" size="large" />
      <Typography variant="body">
        If an order gets cancelled because of getting too old, an error
        describing this will be added to the order.
      </Typography>
      <br></br>
      <Typography variant="heading2">Settings</Typography>
      <Typography variant="body">
        The Settings tab is where you control the users having access to the
        backoffice. This includes both which users have access to the backoffice
        in general, and which tabs they have access to within the backoffice.
        For instance, one user may have permission to view the accounting tab
        but not the order tab.
      </Typography>
      <br></br>
      <Typography variant="body">
        Permissions can be set for each user individually, but it is also
        possible to create roles that can be assigned to one or more users.
        Roles come with specific permissions out of the box, and may be more
        suitable to use if you have multiple users using the backoffice. For
        instance, an &quot;Accounting&quot; role could be used for your
        accountants. This role could have access to view the accounting tab, but
        not to control the users in the backoffice.
      </Typography>
      <br></br>
    </>
  );
};
