/** @jsxImportSource @emotion/react */
import {
  IconName,
  Navbar,
  NavbarItem,
  useTheme,
} from '@quickbit/qb-design-system';
import { ReactNode, useEffect, useRef } from 'react';
import {
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { Endpoints, GetStarted, Integration, Backoffice } from './components';
import {
  MigrationFlow,
  Platforms,
  ProcessingFlow,
} from './components/Integration';
import { SHOW_PROCESSING } from './constants';

interface Tab {
  to: string;
  icon: IconName;
  name: string;
  element: ReactNode;
  subTabs?: Tab[];
}

const TabComponent = ({ icon, name, to, subTabs }: Tab) => {
  const { spacing, breakpoints } = useTheme();
  return (
    <>
      <NavLink to={to} style={{ textDecoration: 'none' }}>
        {({ isActive }: { isActive: boolean }) => (
          <NavbarItem icon={icon} name={name} isActive={isActive} />
        )}
      </NavLink>
      {subTabs && (
        <div css={{ [breakpoints.laptopSmall]: { marginLeft: spacing.xs } }}>
          {subTabs.map((subTab) => (
            <TabComponent
              key={name + '.' + subTab.name}
              {...subTab}
              to={to + '/' + subTab.to}
            />
          ))}
        </div>
      )}
    </>
  );
};

const integrationSubTabs: Tab[] = SHOW_PROCESSING
  ? [
      {
        to: 'migration-flow',
        icon: 'plus',
        name: 'Migration Flow',
        element: <MigrationFlow />,
      },
      {
        to: 'processing-flow',
        icon: 'swap',
        name: 'Processing Flow',
        element: <ProcessingFlow />,
      },
      {
        to: 'platforms',
        icon: 'building',
        name: 'Platforms',
        element: <Platforms />,
      },
    ]
  : [
      {
        to: 'migration-flow',
        icon: 'plus',
        name: 'Migration Flow',
        element: <MigrationFlow />,
      },
      {
        to: 'platforms',
        icon: 'building',
        name: 'Platforms',
        element: <Platforms />,
      },
    ];

const tabs: Tab[] = [
  {
    to: 'start',
    icon: 'bomb',
    name: 'Get started',
    element: <GetStarted />,
  },
  {
    to: 'integration',
    icon: 'send',
    name: 'Integration',
    element: <Integration />,
    subTabs: integrationSubTabs,
  },
  {
    to: 'endpoints',
    icon: 'announcement',
    name: 'Endpoints',
    element: <Endpoints />,
  },
  {
    to: 'backoffice',
    icon: 'community',
    name: 'Backoffice',
    element: <Backoffice />,
  },
];

export const Docs = () => {
  const { palette, spacing, breakpoints } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const scrollRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (location.pathname === '/docs') {
      navigate('/docs/start');
    }
    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, 0);
    }
  }, [location, navigate]);
  return (
    <div
      css={{
        background: palette.grey.x50,
        width: '100vw',
        height: '100vh',
      }}
    >
      <Navbar
        ref={scrollRef}
        tabs={
          <>
            {tabs.map((t) => (
              <TabComponent key={t.to} {...t} />
            ))}
          </>
        }
      >
        <div
          css={{
            maxWidth: '1000px',
            padding: spacing.xxs,
            [breakpoints.laptopSmall]: { padding: 0 },
          }}
        >
          <Routes>
            {tabs.map(({ to, element, subTabs }) => (
              <>
                <Route key={to} path={to} element={element}></Route>
                {subTabs &&
                  subTabs.map((subTab) => (
                    <Route
                      key={subTab.to}
                      path={to + '/' + subTab.to}
                      element={subTab.element}
                    />
                  ))}
              </>
            ))}
          </Routes>
        </div>
      </Navbar>
    </div>
  );
};
