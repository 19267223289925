import { fetchAPI } from 'api';
import { OrderStatus } from 'types';

export interface OrderStatusResponse {
  status: OrderStatus;
}

export const fetchOrderStatus = (orderId: string) => {
  return fetchAPI<OrderStatusResponse>(`v1/order/${orderId}/status`);
};
