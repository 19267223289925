/** @jsxImportSource @emotion/react */

import {
  Typography,
  useTheme,
  CircularProgress,
} from '@quickbit/qb-design-system';
import { useCallback, useEffect, useState } from 'react';

interface TimerProps {
  from: number;
  current: number;
}

const Timer = ({ from, current }: TimerProps) => {
  const { spacing } = useTheme();

  const progress = Number((current / from) * 100);

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        gap: spacing.xxxs,
      }}
    >
      <Typography variant="bodySmall">
        {new Date(current * 1000).toISOString().slice(14, 19)}
      </Typography>
      <CircularProgress value={progress} />
    </div>
  );
};

export const useCountdown = ({
  from,
  restart,
  onEnd,
}: {
  from: number; // in seconds
  onEnd?: () => void;
  restart?: boolean;
}) => {
  const [timerInterval, setTimerInterval] = useState<NodeJS.Timer | null>(null);
  const [timerValue, setTimerValue] = useState(0);

  const start = useCallback(() => {
    if (timerInterval) {
      clearInterval(timerInterval);
    }

    setTimerValue(from);

    const startTime = new Date();
    const newInterval = setInterval(() => {
      const now = new Date();
      const elapsedSeconds = Math.round(
        (now.getTime() - startTime.getTime()) / 1000
      );
      setTimerValue(from - elapsedSeconds);
    }, 1000);
    setTimerInterval(newInterval);
  }, [from, timerInterval]);

  useEffect(() => {
    if (timerValue <= 0 && timerInterval) {
      onEnd && onEnd();
      if (restart) {
        start();
      } else {
        clearInterval(timerInterval);
      }
    }
  }, [timerInterval, timerValue, onEnd, restart, start]);

  return {
    start,
    timer: <Timer from={from} current={timerValue} />,
  };
};
