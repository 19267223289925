import { Typography } from '@quickbit/qb-design-system';
import { HashLink } from 'react-router-hash-link';
import { CodeBlock } from '../../CodeBlock';
import { InlineCode } from '../../InlineCode';

const createNewMerchantExample = `{
  "callback_url": "https://yourdomain.com/api/you-callback-endpoint",
}`;
const createNewMerchantResponseExample = `{
  "merchant_id": "94178756-cb47-456a-b153-7a255a91ab7d",
  "signup_form_url": "https://qb-pay.quickbit.com/sign-up?merchant-id=94178756-cb47-456a-b153-7a255a91ab7d"
}`;

const callbackRequestBodyExample = `{
  "merchant_id": "94178756-cb47-456a-b153-7a255a91ab7d",
  "type": "CREATED",
}`;

const createOrderExample = `{
  "amount": 100,
  "currency": "EUR",
  "callback_url": "https://my-domain.com/api/quickbit-callback",
  "redirect_url": "https://my-domain.com/api/quickbit-redirect",
  "merchant_id": "94178756-cb47-456a-b153-7a255a91ab7d" // <-- This specifies which merchant the order belongs to
}`;

const createOrderResponseExample = `{
  "amount": 100,
  "currency": "EUR",
  "uuid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "created_at": "2023-04-12T06:23:07.558Z",
  "checkout_url": "https://qb-pay.quickbit.com?order-id=3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "reference_id": null,
  "status": "PENDING",
  "creation_amount": 100,
  "merchant_id": "94178756-cb47-456a-b153-7a255a91ab7d" // <-- This specifies which merchant the order belongs to
}`;

const getMerchantsResponseExample = `{
  "merchants": [
    {
      "merchant_id": "94178756-cb47-456a-b153-7a255a91ab7d",
      "name": "Example Merchant Name", // <-- This is the display name of the merchant in Quickbit Pay
      "status": "ACTIVE"
    }
  ],
  "pending_merchants": [
    {
      "merchant_id": "5a8ebee9-bf4d-484f-96dd-f6de43c33d69"
    }
  ]
}`;

export const Platforms = () => {
  return (
    <>
      <Typography variant="heading1">Platforms</Typography>
      <Typography variant="body">
        When integrating as a platform you take control of which merchant you
        are creating an order for. Orders are created in the same way as in the
        regular Quickbit Pay integrations, but with an additional parameter
        specifying which merchant the order is for. Additionally, there is an
        endpoint for creating new merchants linked to your platform, and an
        endpoint for fetching all merchants linked to your platform.
      </Typography>
      <br />
      <Typography variant="heading2">Creating a new merchant</Typography>
      <Typography variant="body">
        The first step after receiving your API key is to create a new merchant
        for your platform. This is done with a <InlineCode>POST</InlineCode> to
        the <InlineCode>/api/platforms/v1/merchants</InlineCode> endpoint. If
        you wish to receive updates about changes to the newly created merchant
        you can provide a <InlineCode>callback_url</InlineCode> to the request
        body. An example can be found below.
      </Typography>
      <CodeBlock language="json" code={createNewMerchantExample} />
      <br></br>
      <Typography>
        The response will contain the id of the newly created merchant as well
        as a URL linking to the signup form that your merchant should fill in in
        order to be activated. This URL needs to be provided to your merchant.
      </Typography>
      <Typography variant="body">
        An example response can be found below.
      </Typography>
      <CodeBlock language="json" code={createNewMerchantResponseExample} />
      <Typography variant="body">
        After the merchant has filled in the form, Quickbit will review the
        information and activate the merchant. Once the merchant has been
        activated, you can start creating orders on behalf of the merchant.
      </Typography>
      <br></br>
      <Typography variant="subtitle">
        Simulating status changes for testing purposes
      </Typography>
      <Typography variant="body">
        Since the merchant is initiated as blocked, it can be difficult to test
        the Quickbit Pay checkout flow for that merchant while integrating. For
        that reason, we have added the possibility of simulating status changes
        for orders into the different callback states. This can be done in two
        ways: either by using the{' '}
        <InlineCode>
          {'POST /api/platforms/v1/merchants/{merchant_id}/simulate-status'}
        </InlineCode>{' '}
        simulation endpoint, or by using the merchant&apos;s backoffice to
        update the merchant status. That is, you can send requests that will
        update the merchant status, or you can use an interface to do it. If you
        specified a <InlineCode>callback_url</InlineCode> when creating the
        pending merchant, you will also receive callbacks on these status
        changes, as described in the next section.
      </Typography>
      <br></br>
      <Typography variant="body">
        The simulation endpoint and backoffice action are only available in the
        Quickbit Pay test environment.
      </Typography>
      <br></br>
      <Typography variant="heading2">Merchant callbacks</Typography>
      <Typography variant="body">
        If a <InlineCode>callback_url</InlineCode> was provided to the create
        merchant request, 3 different <InlineCode>POST</InlineCode> requests
        will be sent to said URL when changes occur to the merchant. An example
        of a request body that we will send can be found below.
      </Typography>
      <CodeBlock language="json" code={callbackRequestBodyExample} />
      <Typography variant="body">
        These are the different callback types:
      </Typography>
      <ul>
        <li>
          <Typography variant="body">
            <InlineCode>CREATED</InlineCode> - This will be sent when the
            merchant has responded to the signup form. The newly created
            merchant will be blocked until the submitted information has been
            approved by Quickbit.
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            <InlineCode>ACTIVATED</InlineCode> - This will be sent when a
            merchant status moves from being blocked to being active. This will
            for example be sent when Quickbit approves a newly submitted signup
            information.
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            <InlineCode>BLOCKED</InlineCode> - This will be sent when a merchant
            status moves from being active to being blocked. This should
            hopefully never happen, but could occur if Quickbit notices
            suspicious activity from the merchant.
          </Typography>
        </li>
      </ul>
      <Typography variant="heading2">Creating orders</Typography>
      <Typography variant="body">
        Creating orders is done in the same way as for a regular Quickbit Pay
        integration (more details can be found{' '}
        <HashLink to={'/docs/integration#create-order'}>here</HashLink>) with
        the exception that a merchant ID has to be provided. See the example
        request body below.
      </Typography>
      <CodeBlock language="json" code={createOrderExample} />
      <br></br>
      <Typography variant="body">
        The merchant ID is included in the response as well. The same goes for
        fetching an order using the{' '}
        <InlineCode>GET /api/pay/v1/order</InlineCode> endpoint. See the example
        response below.
      </Typography>
      <CodeBlock language="json" code={createOrderResponseExample} />
      <br></br>
      <Typography variant="heading2">Fetching merchants</Typography>
      <Typography variant="body">
        The platform integration also provides an endpoint for fetching the
        merchants linked to your platform. This is done using the{' '}
        <InlineCode>GET /api/platforms/v1/merchants</InlineCode> endpoint. An
        example response can be found below.
      </Typography>
      <CodeBlock language="json" code={getMerchantsResponseExample} />
      <br></br>
      <Typography variant="body">
        The <InlineCode>merchants</InlineCode> list contains all merchants that
        have responded to the signup form, while the{' '}
        <InlineCode>pending_merchants</InlineCode> list contains the merchants
        that have not. The status of a merchant is{' '}
        <InlineCode>BLOCKED</InlineCode> until it has been approved by Quickbit,
        at which point it becomes <InlineCode>ACTIVE</InlineCode>. Orders can
        only be created for active merchants.
      </Typography>
      <br />
      <Typography variant="heading2">Settlements</Typography>
      <Typography variant="body">
        The merchants that are linked to your platform will be settled in the
        same way as regular Quickbit Pay merchants. More on this can be found{' '}
        <HashLink to={'/docs/start#settlement'}>here</HashLink>.
      </Typography>
    </>
  );
};
