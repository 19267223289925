/** @jsxImportSource @emotion/react */
import { Typography, useTheme } from '@quickbit/qb-design-system';
import { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

export const Paragraph = ({ children }: Props) => {
  const { palette } = useTheme();
  return (
    <Typography variant="bodyLarge" color={palette.grey.default}>
      {children}
    </Typography>
  );
};
