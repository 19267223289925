/** @jsxImportSource @emotion/react */

import {
  Card,
  Checkbox,
  Errors,
  Input,
  ListItem,
  Select,
  Setters,
  UtilityButton,
  createStyles,
  useTheme,
} from '@quickbit/qb-design-system';
import { uniqueId } from 'lodash';
import { RelatedPersonFormData } from 'pages/SignUp/types';
import { DateInput } from './DateInput';

interface Props {
  data: RelatedPersonFormData[];
  editable: boolean;
  setters: Setters<RelatedPersonFormData[]>;
  errors: Errors<RelatedPersonFormData[]>;
  onBlur?: () => void;
}

export const RelatedPeople = ({
  data,
  errors,
  editable,
  setters,
  onBlur,
}: Props) => {
  const { spacing } = useTheme();
  const styles = useStyles();

  return (
    <>
      <div css={{ display: 'grid', gap: spacing.s }}>
        {data.map(
          (
            {
              firstName,
              lastName,
              isPep,
              dateOfBirth,
              id,
              placeOfBirth,
              type,
              role,
              ssn,
            },
            idx
          ) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return (
              <Card key={id}>
                <div
                  css={{
                    display: 'grid',
                    gap: spacing.xxs,
                  }}
                >
                  <Select
                    label="Type"
                    options={[
                      { label: 'Board member', value: 'BOARD_MEMBER' },
                      {
                        label: 'Senior management',
                        value: 'SENIOR_MANAGEMENT',
                      },
                      { label: 'Shareholder', value: 'SHAREHOLDER' },
                    ]}
                    values={[type]}
                    onSelect={([type]) => setters.list[idx].type.set(type)}
                    helperText={errors[idx].type ?? undefined}
                    error={!!errors[idx].type}
                  />
                  <Input
                    label="First name"
                    value={firstName}
                    disabled={!editable}
                    onChange={(e) =>
                      setters.list[idx].firstName.set(e.target.value)
                    }
                    onBlur={onBlur}
                    error={!!errors[idx].firstName}
                    helperText={errors[idx].firstName}
                  />
                  <Input
                    label="Last name"
                    value={lastName}
                    disabled={!editable}
                    onChange={(e) =>
                      setters.list[idx].lastName.set(e.target.value)
                    }
                    onBlur={onBlur}
                    error={!!errors[idx].lastName}
                    helperText={errors[idx].lastName}
                  />
                  <DateInput
                    title="Date of birth"
                    value={dateOfBirth}
                    disabled={!editable}
                    onChange={(d) => setters.list[idx].dateOfBirth.set(d)}
                    error={!!errors[idx].dateOfBirth}
                    helperText={errors[idx].dateOfBirth ?? ''}
                  />
                  <Input
                    label="Social security number"
                    value={ssn}
                    disabled={!editable}
                    onChange={(e) => setters.list[idx].ssn.set(e.target.value)}
                    onBlur={onBlur}
                    error={!!errors[idx].ssn}
                    helperText={errors[idx].ssn}
                  />
                  {type && type !== 'SHAREHOLDER' && (
                    <>
                      <Input
                        label="Place of birth"
                        value={placeOfBirth}
                        disabled={!editable}
                        onChange={(e) =>
                          setters.list[idx].placeOfBirth.set(e.target.value)
                        }
                        onBlur={onBlur}
                        error={!!errors[idx].placeOfBirth}
                        helperText={errors[idx].placeOfBirth}
                      />
                      <Input
                        label="Role"
                        value={role}
                        disabled={!editable}
                        onChange={(e) =>
                          setters.list[idx].role.set(e.target.value)
                        }
                        onBlur={onBlur}
                        error={!!errors[idx].role}
                        helperText={errors[idx].role}
                      />
                    </>
                  )}
                  <ListItem
                    css={[
                      styles.listItem,
                      !editable && styles.listItemDisabled,
                    ]}
                    title="Please check if this is a Politically Exposed Person (PEP) or Relatives and Close Associates (RCA)."
                    icon={<Checkbox checked={isPep} />}
                    disabled={!editable}
                    onClick={() => setters.list[idx].isPep.set(!isPep)}
                  />
                  <UtilityButton
                    text="Remove"
                    onClick={() => setters.set(data.filter((p) => p.id !== id))}
                    disabled={!editable}
                  />
                </div>
              </Card>
            );
          }
        )}
      </div>
      <div css={{ marginTop: spacing.xs }}>
        <UtilityButton
          text="Add person"
          icon={'plus'}
          onClick={() =>
            setters.set([
              ...data,
              {
                dateOfBirth: null,
                firstName: '',
                isPep: false,
                lastName: '',
                id: uniqueId('new-'),
                role: '',
                placeOfBirth: '',
                type: '',
                ssn: '',
              },
            ])
          }
          disabled={!editable}
        />
      </div>
    </>
  );
};

const useStyles = createStyles(({ spacing, palette }) => ({
  listItem: {
    borderRadius: spacing.xxxs,
    border: `1px solid ${palette.grey.x200}`,
    ':hover': {
      borderColor: palette.grey.x600,
    },
  },
  listItemDisabled: {
    ':hover': {
      borderColor: 'inherit',
    },
  },
}));
