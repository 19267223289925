/** @jsxImportSource @emotion/react */
import {
  getCurrencyName,
  Icon,
  IconName,
  createStyles,
  List,
  ListItem,
  useTheme,
} from '@quickbit/qb-design-system';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Wallet } from 'types';

interface Props {
  wallets: Wallet[];
  value: string | undefined;
  onChange: (wallet: Wallet) => void;
  showDropdown?: boolean;
}

export const WalletSelect = ({
  wallets,
  value,
  onChange,
  showDropdown,
}: Props) => {
  const { formatMessage } = useIntl();
  const [showWalletList, setShowWalletList] = useState(showDropdown);
  const { spacing } = useTheme();
  const styles = useStyles();

  const onWalletSelected = (selectedWallet: Wallet) => {
    onChange(selectedWallet);
    setShowWalletList(false);
  };

  if (!wallets.length) return null;

  const wallet = wallets.find((w) => w.currency === value);

  return (
    <li css={styles.default}>
      <ul
        css={[styles.selectWallet]}
        onClick={() => setShowWalletList(!showWalletList)}
      >
        {!wallet && (
          <ListItem
            title={formatMessage({ id: 'walletSelect.placeholder' })}
            action={
              <div
                css={[
                  styles.chevronContainer,
                  showWalletList && { transform: 'rotate(90deg)' },
                ]}
              >
                <Icon name="chevron" size={spacing.xxs} />
              </div>
            }
          />
        )}
        {wallet && (
          <ListItem
            icon={wallet.currency as IconName}
            title={getCurrencyName(wallet.currency)}
            action={
              <div
                css={[
                  styles.chevronContainer,
                  showWalletList && { transform: 'rotate(90deg)' },
                ]}
              >
                <Icon name="chevron" size={spacing.xxs} />
              </div>
            }
          />
        )}
      </ul>
      {showWalletList && (
        <div css={styles.walletList}>
          <List>
            {wallets.map((wallet) => (
              <ListItem
                key={wallet.currency}
                icon={wallet.currency as IconName}
                title={getCurrencyName(wallet.currency)}
                onClick={() => onWalletSelected(wallet)}
              />
            ))}
          </List>
        </div>
      )}
    </li>
  );
};

const useStyles = createStyles(({ palette, spacing, shape }) => ({
  default: {
    cursor: 'pointer',
    position: 'relative',
    width: '100%',
    borderBottom: `1px solid ${palette.grey.x100}`,
  },
  chevronContainer: {
    marginTop: spacing.xxxs,
    transform: 'rotate(-90deg)',
    svg: { fill: 'none', stroke: palette.grey.x300 },
  },
  selectWallet: {
    width: '100%',
    borderRadius: shape.borderRadius,
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  walletList: {
    width: '100%',
    height: 'auto',
    maxHeight: 300,
    marginTop: spacing.xxxs,
    overflow: 'hidden',
    overflowY: 'scroll',
    position: 'absolute',
    top: '100%',
    left: 0,
    border: `1px solid ${palette.grey.x100}`,
    borderRadius: shape.borderRadius,
    boxShadow: `0 4px 4px 0 rgba(0 0 0 / 0.1)`,
    zIndex: '10',

    '& > ul': {
      border: 'none',
    },
  },
}));
