import { Paragraph } from '../components';

/** @jsxImportSource @emotion/react */
export const SendInPassportReminder = ({
  documentEmail,
}: {
  documentEmail: string;
}) => {
  return (
    <Paragraph>
      Please remember to also send in a copy of the passport of each member of
      the board of directors, senior management or significant shareholder to{' '}
      {documentEmail}.
    </Paragraph>
  );
};
