/** @jsxImportSource @emotion/react */
import { useTheme } from '@quickbit/qb-design-system';
import { ReactNode } from 'react';

export const InlineCode = ({ children }: { children: ReactNode }) => {
  const { palette } = useTheme();
  return (
    <code
      css={{
        background: palette.grey.x300,
        color: palette.primary.x600,
        borderRadius: '4px',
        padding: '1px',
      }}
    >
      {children}
    </code>
  );
};
