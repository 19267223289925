import { CryptoCurrency, Currency } from '@quickbit/qb-design-system';

export enum OrderStatus {
  INITIATED = 'INITIATED',
  ADDRESS_CREATED = 'ADDRESS_CREATED',
  PROCESSING_INTERNAL = 'PROCESSING_INTERNAL',
  PROCESSING_EXTERNAL = 'PROCESSING_EXTERNAL',
  COMPLETE = 'COMPLETE',
  CRYPTO_RECEIVED = 'CRYPTO_RECEIVED',
  CRYPTO_SOLD = 'CRYPTO_SOLD',
  INVALID_AMOUNT = 'INVALID_AMOUNT',
  REFUND_ADDRESS_SUBMITTED = 'REFUND_ADDRESS_SUBMITTED',
  REFUND_CRYPTO_RECEIVED = 'REFUND_CRYPTO_RECEIVED',
  REFUNDING = 'REFUNDING',
  REFUNDED = 'REFUNDED',
  CANCELLED = 'CANCELLED',
}

export interface Order {
  amount: number;
  currency: Currency;
  merchantId: string;
  merchantName: string;
  hideQbBrand: boolean;
  uuid: string;
  createdAt: Date;
  status: OrderStatus;
  redirectUrl?: string;
  failureRedirectUrl?: string;
}

export interface Wallet {
  currency: CryptoCurrency;
}
