import { Currency, MoneyScaled } from '@quickbit/qb-design-system';
import { OrderStatus, Wallet } from 'types';
import { fetchAPI } from './fetchAPI';

export interface Refund {
  receivedAmount: MoneyScaled;
}

export interface OrderResponse {
  amount: number;
  currency: Currency;
  merchantId: string;
  merchantName: string;
  hideQbBrand: boolean;
  hideFiatAmount: boolean;
  uuid: string;
  createdAt: Date;
  status: OrderStatus;
  refund?: Refund;
  redirectUrl?: string;
  failureRedirectUrl?: string;
}

export interface OrderOverviewResponse {
  order: OrderResponse;
  wallets: Wallet[];
}

export const getOrder = async (orderId: string) => {
  return await fetchAPI<OrderOverviewResponse>(`v1/order/${orderId}`);
};
