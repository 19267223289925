/** @jsxImportSource @emotion/react */
import { Typography, createStyles, useTheme } from '@quickbit/qb-design-system';
import { ImageKey } from 'api/contentful/getImage';
import { Image } from 'components';

interface Props {
  illustration: ImageKey;
  heading: string;
  subheading: string;
}

export const IllustrationWithText = ({
  illustration,
  heading,
  subheading,
}: Props) => {
  const styles = useStyle();
  const { spacing, palette } = useTheme();

  return (
    <div css={styles.container}>
      <span css={styles.illustration}>
        <Image name={illustration} size={spacing.xxl} />
      </span>
      <div css={styles.heading}>
        <Typography variant="subtitle">{heading}</Typography>
      </div>
      <div css={styles.subheading}>
        <Typography variant="body" color={palette.grey.x500}>
          {subheading}
        </Typography>
      </div>
    </div>
  );
};

const useStyle = createStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  illustration: {
    marginTop: spacing.xs,
  },
  heading: {
    textAlign: 'center',
    marginTop: spacing.xs,
  },
  subheading: {
    marginTop: spacing.xxxs,
    padding: spacing.xxxs,
    textAlign: 'center',
  },
}));
