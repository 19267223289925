/** @jsxImportSource @emotion/react */
import {
  Typography,
  createStyles,
  useTheme,
  Button,
} from '@quickbit/qb-design-system';
import { useIntl } from 'react-intl';
import { type ErrorResponse } from 'api/fetchAPI';
import { Image } from 'components';
import { useOrderId } from 'context';

interface Props {
  error: ErrorResponse;
  tryAgainAction?: () => void;
  extraInfo?: string;
}

export const ErrorPage = ({ error, tryAgainAction, extraInfo }: Props) => {
  const orderId = useOrderId();
  const styles = useStyle();
  const { spacing, palette } = useTheme();
  const { formatMessage } = useIntl();

  return (
    <div css={styles.container}>
      <span css={styles.emptyStateImage}>
        <Image name="emptyState" size={spacing.xl} />
      </span>
      <div css={styles.errorHeading}>
        <Typography variant="subtitle">
          {formatMessage({ id: 'page.error.heading' })}
        </Typography>
      </div>
      <div css={styles.errorReason}>
        {error.errorCode == 'FORM_VALIDATION_FAILED' ? (
          error.formErrors.map((formError) => (
            <Typography variant="subtitle" key={formError.reason}>
              {formError.reason}
            </Typography>
          ))
        ) : (
          <Typography variant="body" color={palette.grey.x500}>
            {error.reason}
          </Typography>
        )}
        {extraInfo && (
          <Typography variant="body" color={palette.grey.x500}>
            {extraInfo}
          </Typography>
        )}
      </div>
      <div css={styles.orderId}>
        <Typography variant="body" color={palette.grey.x500}>
          {`${formatMessage({
            id: 'page.error.orderID',
          })} ${orderId}`}
        </Typography>
      </div>
      <Button
        text={formatMessage({ id: 'page.error.button.tryAgain' })}
        onClick={tryAgainAction}
      />
    </div>
  );
};

const useStyle = createStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  emptyStateImage: {
    marginTop: `${spacing.xl}`,
  },
  errorReason: {
    marginTop: `${spacing.xxxs}`,
    textAlign: 'center',
  },
  orderId: {
    marginTop: `${spacing.xxxs}`,
    textAlign: 'center',
  },
  errorHeading: {
    marginTop: `${spacing.m}`,
  },
}));
