/** @jsxImportSource @emotion/react */
import { createStyles, Typography, Icon } from '@quickbit/qb-design-system';
import { Dispatch, FunctionComponent, ReactNode } from 'react';

interface Props {
  title?: string;
  children?: ReactNode;
  showModal: boolean;
  closeModal: Dispatch<boolean>;
}

export const Modal: FunctionComponent<Props> = ({
  title,
  children,
  showModal,
  closeModal,
}) => {
  const styles = useStyles();

  return (
    <>
      {showModal ? (
        <div css={styles.main}>
          <div css={styles.modal}>
            <div css={styles.header}>
              {title && <Typography variant="bodyBold">{title}</Typography>}
              <span css={styles.closeIcon} onClick={() => closeModal(false)}>
                <Icon name="close" />
              </span>
            </div>
            <div css={styles.content}>{children}</div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const useStyles = createStyles(({ palette, spacing, breakpoints, sizing }) => ({
  main: {
    minHeight: '100vh',
    position: 'absolute',
    top: '0',
    width: '100%',
    zIndex: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    background: palette.white,
    [breakpoints.laptopSmall]: {
      background: `${palette.black}54`,
      paddingTop: spacing.s,
      marginTop: spacing.m,
      minHeight: `calc(100vh - ${spacing.m})`,
    },
  },
  modal: {
    width: '100%',
    [breakpoints.laptopSmall]: {
      maxWidth: sizing.medium,
      borderRadius: spacing.xxxs,
      background: palette.white,
    },
  },
  header: {
    position: 'fixed',
    left: '0',
    top: '0',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing.xxs,
    background: palette.white,
    borderBottom: `1px solid ${palette.grey.x200}`,
    [breakpoints.laptopSmall]: {
      position: 'relative',
      borderRadius: `${spacing.xxxs}  ${spacing.xxxs} 0 0`,
    },
  },
  content: {
    padding: spacing.xxs,
    marginTop: spacing.m,
    marginBottom: spacing.m,
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '100%',
    [breakpoints.laptopSmall]: {
      margin: '0',
      maxHeight: `calc(${sizing.large} - ${spacing.xl})`,
    },
  },
  closeIcon: {
    cursor: 'pointer',
    svg: {
      stroke: palette.grey.default,
    },
  },
}));
