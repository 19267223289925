import { fetchAPI } from 'api';
import { OrderStatus } from 'types';
import { KybSave, Merchant } from './types';

export interface SubmitResponse {
  merchantId: OrderStatus;
  riskScore?: number;
}

const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day}`;
};

export const submitAnswers = (
  email: string,
  merchant: Merchant,
  kyb: KybSave
) => {
  const newKyb = {
    ...kyb,
    onboardingDate: formatDate(new Date()),
    incorporationDate: formatDate(kyb.incorporationDate),
    relatedPeople: kyb.relatedPeople.map((p) => ({
      ...p,
      dateOfBirth: formatDate(p.dateOfBirth),
    })),
  };
  return fetchAPI<SubmitResponse>(`v1/merchant:initialize`, {
    method: 'POST',
    body: {
      email,
      merchant,
      kyb: newKyb,
    },
  });
};
