import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAsync } from 'react-use';
import { getOrder } from 'api';
import { useOrderId, useSetOrder } from 'context';
import { redirectBasedOnStatus } from 'utils';

export const useOrderDetails = () => {
  const orderId = useOrderId();
  const setOrder = useSetOrder();
  const navigate = useNavigate();

  const { value: order, error } = useAsync(async () => {
    const orderResponse = await getOrder(orderId);
    setOrder(orderResponse.order);

    redirectBasedOnStatus(orderResponse.order.status, navigate);

    return orderResponse;
  }, [navigate]);

  useEffect(() => {
    if (error && error.errorCode === 'GET_ORDER_NOT_FOUND') {
      navigate('/not-found');
    }
  }, [error, navigate]);

  useEffect(() => {
    if (order && order.order.hideQbBrand) {
      const link: HTMLLinkElement | null =
        document.querySelector("link[rel~='icon']");
      // This will always be true
      if (link) {
        link.href = '';
      }
    }
  }, [order]);

  return {
    order,
    error,
  };
};
