const space = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
const environment = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT_ID;
const accessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;

const url = `https://graphql.contentful.com/content/v1/spaces/${space}/environments/${environment}`;
const headers = {
  'content-type': 'application/json',
  authorization: `Bearer ${accessToken}`,
};

export type Query = {
  query: string;
  variables?: { [key: string]: undefined | number | string | string[] };
};

const queryContentfulGraphql = async (body: string): Promise<Response> => {
  const requestParams = { method: 'POST', headers, body };
  const res = await fetch(url, requestParams);
  if (res.status === 200) {
    return res;
  }
  throw Error(
    `Unknown error when querying contentful Graphql. StatusCode: ${res.status} ${res.statusText}`
  );
};

export async function contentfulGraphql<TData>(query: Query): Promise<TData> {
  const augmentedBody: Query = {
    query: query.query,
    variables: query.variables,
  };

  const res = await queryContentfulGraphql(JSON.stringify(augmentedBody));

  const { data, errors } = await res.json();

  // We can get errors even if the HTTP status was successful, for example when
  // there's a broken entry reference.
  if (errors?.length) {
    // TODO: When we get a location in the error we can mark that location in
    // the query when we log it to the console.
    throw new Error(
      `${res.status} ${res.statusText} in Contentful GraphQL request.
  
  Errors: ${JSON.stringify(errors, null, 2)}
  
  Query:
  
  ${augmentedBody.query}`
    );
  }

  return data;
}
