/** @jsxImportSource @emotion/react */
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Input,
  ListItem,
  Select,
  Snackbar,
  Typography,
  createStyles,
  useForm,
  useTheme,
} from '@quickbit/qb-design-system';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAsyncFn, useLocalStorage, useMount } from 'react-use';
import { COUNTRY_OPTIONS } from 'utils';
import { submitAnswers } from './api';
import {
  ContactInformationForm,
  DateInput,
  RelatedPeople,
  Section,
  Paragraph,
  SendInPassportReminder,
} from './components';
import {
  BUSINESS_SECTOR_OPTIONS,
  BusinessSector,
  ContactInformation,
  KybFormData,
  KybSave,
  Merchant,
} from './types';
import { isValidEmail, kybValidators, merchantValidators } from './utils';

const documentEmail = 'kyc@quickbit.com';

const defaultMerchantForm: Merchant = {
  merchantId: undefined,
  name: '',
  countryCode: '',
  iban: '',
  bic: '',
  paymentReference: 'Quickbit',
};

const defaultContactInfo: ContactInformation = {
  address1: '',
  country: '',
  postalNumber: '',
  postalRegion: '',
};

const defaultKybForm: KybFormData = {
  fullCompanyName: '',
  previousCompanyNames: '',
  incorporationDate: null,
  registrationNumber: '',
  incorporationCountry: '',
  businessSectors: [],
  expectedMonthlyVolume: 0,
  licenseForBusiness: '',
  jurisdictions: '',
  turnover: 0,
  expectedMonthlyTransactions: 0,
  usaConnection: false,
  registeredAddress: defaultContactInfo,
  primaryAddress: { ...defaultContactInfo },
  relatedPeople: [],
  businessOperations: '',
  authorizedSignatory: '',
};

export const SignUp = () => {
  const [params] = useSearchParams();
  const { spacing, palette, breakpoints } = useTheme();
  const styles = useStyles();

  const {
    fields: merchantForm,
    setters: merchantFormSetters,
    errors: merchantFormErrors,
    hasErrors: merchantFormHasErrors,
  } = useForm<Merchant>(defaultMerchantForm, merchantValidators);
  const {
    fields: kybForm,
    setters: kybFormSetters,
    errors: kybFormErrors,
    hasErrors: kybFormHasErrors,
  } = useForm<KybFormData>(defaultKybForm, kybValidators);

  const [email, setEmail, clearEmail] = useLocalStorage<string>('Email');
  const invalidEmail = !isValidEmail(email);

  const [localStoreMerchant, setLocalStoreMerchant, clearLocalStoreMerchant] =
    useLocalStorage<Merchant>('Merchant');
  const [localStoreKyb, setLocalStoreKyb, clearLocalStoreKyb] =
    useLocalStorage<KybFormData>('kyb');

  useMount(() => {
    if (localStoreMerchant) {
      merchantFormSetters.set(localStoreMerchant);
    }
    if (localStoreKyb) {
      kybFormSetters.set({
        ...localStoreKyb,
        incorporationDate: localStoreKyb.incorporationDate
          ? new Date(localStoreKyb.incorporationDate)
          : null,
        relatedPeople: localStoreKyb.relatedPeople.map((p) => ({
          ...p,
          dateOfBirth: p.dateOfBirth ? new Date(p.dateOfBirth) : null,
        })),
      });
    }
    const merchantId = params.get('merchant-id');
    if (merchantId) {
      merchantFormSetters.merchantId?.set(merchantId);
    }
  });

  const [{ value, loading, error }, submit] = useAsyncFn(async () => {
    if (!email || merchantFormHasErrors || kybFormHasErrors) return null;
    const response = await submitAnswers(
      email,
      merchantForm,
      kybForm as KybSave
    );
    clearEmail();
    clearLocalStoreKyb();
    clearLocalStoreMerchant();
    return response;
  }, [email, merchantForm, kybForm, merchantFormHasErrors, kybFormHasErrors]);

  const [showError, setShowError] = useState(false);
  useEffect(() => {
    if (error) {
      setShowError(true);
    }
  }, [error]);

  const handleSaveToLocalStorage = () => {
    setLocalStoreMerchant(merchantForm);
    setLocalStoreKyb(kybForm);
  };

  return (
    <div css={{ '>div:nth-of-type(even)': { background: palette.grey.x50 } }}>
      <Section title="Let's get you signed up">
        <Paragraph>First we need your contact details.</Paragraph>
        <div
          css={{
            display: 'grid',
            [breakpoints.laptopSmall]: {
              gridTemplateColumns: 'repeat(3, 1fr)',
            },
          }}
        >
          <Input
            label="Email address"
            value={email ?? ''}
            onChange={(e) => setEmail(e.target.value)}
            helperText={invalidEmail ? 'Please enter a valid email' : undefined}
            error={invalidEmail}
          />
        </div>
      </Section>
      <Section title="General Information">
        <Paragraph>
          Please provide the following general information about your business.
        </Paragraph>
        <div css={{ display: 'grid', gap: spacing.xs }}>
          <Input
            label="Company Display Name"
            value={merchantForm.name}
            onChange={(e) => {
              merchantFormSetters.name.set(e.target.value);
            }}
            onBlur={handleSaveToLocalStorage}
            helperText={
              merchantFormErrors.name ??
              'E.g. Quickbit. This is the display name of the merchant. It will be shown in the checkout pages.'
            }
            error={!!merchantFormErrors.name}
          />
          <Input
            label="Full company name"
            value={kybForm.fullCompanyName}
            onChange={(e) => kybFormSetters.fullCompanyName.set(e.target.value)}
            onBlur={handleSaveToLocalStorage}
            helperText={
              kybFormErrors.fullCompanyName ??
              'This is the legal name. E.g. Quickbit Eu AB (publ)'
            }
            error={!!kybFormErrors.fullCompanyName}
          />
          <Input
            label="Previous company name(s)"
            value={kybForm.previousCompanyNames ?? ''}
            onChange={(e) =>
              kybFormSetters.previousCompanyNames?.set(e.target.value)
            }
            onBlur={handleSaveToLocalStorage}
          />
          <Select
            label="Country of incorporation"
            options={COUNTRY_OPTIONS}
            values={
              merchantForm.countryCode ? [merchantForm.countryCode] : undefined
            }
            onSelect={([selected]) => {
              merchantFormSetters.countryCode.set(selected);
              kybFormSetters.incorporationCountry.set(selected);
            }}
            helperText={
              merchantFormErrors.countryCode ??
              kybFormErrors.incorporationCountry ??
              undefined
            }
            error={
              !!merchantFormErrors.countryCode ||
              !!kybFormErrors.incorporationCountry
            }
          />
          <DateInput
            title="Incorporation date"
            value={kybForm.incorporationDate}
            onChange={(e) => kybFormSetters.incorporationDate.set(e)}
            error={!!kybFormErrors.incorporationDate}
            helperText={kybFormErrors.incorporationDate ?? ''}
          />
          <Input
            label="Registration number"
            value={kybForm.registrationNumber}
            onChange={(e) =>
              kybFormSetters.registrationNumber.set(e.target.value)
            }
            onBlur={handleSaveToLocalStorage}
            error={!!kybFormErrors.registrationNumber}
            helperText={kybFormErrors.registrationNumber}
          />
          <Input
            label="IBAN"
            value={merchantForm.iban}
            onChange={(e) => merchantFormSetters.iban.set(e.target.value)}
            onBlur={handleSaveToLocalStorage}
            helperText={merchantFormErrors.iban}
            error={!!merchantFormErrors.iban}
          />

          <Input
            label="BIC"
            value={merchantForm.bic}
            onChange={(e) => merchantFormSetters.bic.set(e.target.value)}
            onBlur={handleSaveToLocalStorage}
            helperText={merchantFormErrors.bic}
            error={!!merchantFormErrors.bic}
          />
          <Input
            label="Payment reference"
            value={merchantForm.paymentReference ?? ''}
            onChange={(e) =>
              merchantFormSetters.paymentReference.set(e.target.value)
            }
            onBlur={handleSaveToLocalStorage}
            helperText={merchantFormErrors.paymentReference}
            error={!!merchantFormErrors.paymentReference}
          />
          <Input
            label="Description of the company’s business operations"
            type="textarea"
            rows={3}
            value={kybForm.businessOperations}
            onChange={(e) =>
              kybFormSetters.businessOperations.set(e.target.value)
            }
            error={!!kybFormErrors.businessOperations}
            helperText={
              kybFormErrors.businessOperations ??
              'Please add information about the company’s source of funds'
            }
          />
          <Input
            label="Company’s authorized signatory"
            value={kybForm.authorizedSignatory}
            onChange={(e) =>
              kybFormSetters.authorizedSignatory.set(e.target.value)
            }
            error={!!kybFormErrors.authorizedSignatory}
            helperText={kybFormErrors.authorizedSignatory}
          />
          <ListItem
            css={[styles.listItemBorder]}
            title="Please check if your business has any connection / ties to USA."
            icon={<Checkbox checked={kybForm.usaConnection} />}
            onClick={() =>
              kybFormSetters.usaConnection.set(!kybForm.usaConnection)
            }
          />
          <ContactInformationForm
            label="Registered address"
            data={kybForm.registeredAddress}
            editable={true}
            setters={kybFormSetters.registeredAddress}
            errors={kybFormErrors.registeredAddress}
            onBlur={handleSaveToLocalStorage}
          />

          <div
            css={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              [breakpoints.laptopSmall]: {
                gridTemplateColumns: 'repeat(3, 1fr)',
              },
            }}
          >
            <Button
              variant="primary"
              text="Copy registered address to primary address below"
              onClick={() => {
                kybFormSetters.primaryAddress.set({
                  ...kybForm.registeredAddress,
                });
              }}
            />
          </div>

          <ContactInformationForm
            label="Primary address"
            data={kybForm.primaryAddress}
            editable={true}
            setters={kybFormSetters.primaryAddress}
            errors={kybFormErrors.primaryAddress}
            onBlur={handleSaveToLocalStorage}
          />
        </div>
      </Section>
      <Section title="Industry">
        <Paragraph>
          Check the boxes that most accurately describe the industries that your
          business operates in. One or more may apply.
        </Paragraph>
        <CheckboxGroup
          options={BUSINESS_SECTOR_OPTIONS}
          values={kybForm.businessSectors}
          onChange={(s) =>
            kybFormSetters.businessSectors.set(s as BusinessSector[])
          }
        />
      </Section>
      <Section title="Board of Directors, Senior Management and Shareholders">
        <Paragraph>
          Please specify the full name, place of birth and date of birth for
          each member of the board of directors, senior management, shareholder
          or ultimate beneficial owner with a holding (direct and/or indirect)
          of 25% or more.
        </Paragraph>
        <RelatedPeople
          data={kybForm.relatedPeople}
          editable={true}
          setters={kybFormSetters.relatedPeople}
          errors={kybFormErrors.relatedPeople}
          onBlur={handleSaveToLocalStorage}
        />
        <Paragraph>
          You also need to send in a copy of the passport of each person whom
          you listed above. Please send it to {documentEmail}
        </Paragraph>
      </Section>
      <Section title="Additional information">
        <Typography variant="bodyLarge" color={palette.grey.default}>
          Do you hold any licenses or registrations for conducting your business
          operations? If yes, please provide a short description:
        </Typography>
        <Input
          label="Short description"
          type="textarea"
          rows={3}
          value={kybForm.licenseForBusiness ?? ''}
          onChange={(e) =>
            kybFormSetters.licenseForBusiness?.set(e.target.value)
          }
          onBlur={handleSaveToLocalStorage}
        />
        <Typography variant="bodyLarge" color={palette.grey.default}>
          Which customer jurisdictions do you plan to open Quickbit Pay for?
        </Typography>
        <Input
          type="text"
          value={kybForm.jurisdictions}
          onChange={(e) => kybFormSetters.jurisdictions.set(e.target.value)}
          error={!!kybFormErrors.jurisdictions}
          helperText={kybFormErrors.jurisdictions}
          onBlur={handleSaveToLocalStorage}
        />
        <Typography variant="bodyLarge" color={palette.grey.default}>
          What was the company’s turnover last year?
        </Typography>
        <Input
          type="number"
          value={kybForm.turnover.toString()}
          endAdornment="€"
          onChange={(e) =>
            kybFormSetters.turnover.set(
              e.target.value ? parseInt(e.target.value) : 0
            )
          }
          error={!!kybFormErrors.turnover}
          helperText={kybFormErrors.turnover}
          onBlur={handleSaveToLocalStorage}
        />
        <Typography variant="bodyLarge" color={palette.grey.default}>
          How much volume (EUR) do you expect to transact with Quickbit monthly?
          If not possible to calculate, please provide a rough estimate.
        </Typography>
        <Input
          label="Expected monthly volume"
          type="number"
          value={kybForm.expectedMonthlyVolume.toString()}
          endAdornment="€"
          onChange={(e) =>
            kybFormSetters.expectedMonthlyVolume.set(
              e.target.value ? parseInt(e.target.value) : 0
            )
          }
          error={!!kybFormErrors.expectedMonthlyVolume}
          helperText={kybFormErrors.expectedMonthlyVolume}
          onBlur={handleSaveToLocalStorage}
        />
        <Typography variant="bodyLarge" color={palette.grey.default}>
          How many transactions do you expect to receive with Quickbit Pay
          monthly? If not possible to calculate, please provide a rough
          estimate:
        </Typography>
        <Input
          label="Expected monthly transactions"
          type="number"
          value={kybForm.expectedMonthlyTransactions.toString()}
          onChange={(e) =>
            kybFormSetters.expectedMonthlyTransactions.set(
              e.target.value ? parseInt(e.target.value) : 0
            )
          }
          error={!!kybFormErrors.expectedMonthlyTransactions}
          helperText={kybFormErrors.expectedMonthlyTransactions}
          onBlur={handleSaveToLocalStorage}
        />
      </Section>
      <Section
        title={value ? 'Great job!' : 'Almost there'}
        {...(value
          ? {}
          : {
              subtitle:
                'Click to submit your answers and start the onboarding process.',
            })}
      >
        {!value && (
          <>
            <SendInPassportReminder documentEmail={documentEmail} />
            <div
              css={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                [breakpoints.laptopSmall]: {
                  gridTemplateColumns: 'repeat(3, 1fr)',
                },
                marginTop: spacing.s,
              }}
            >
              <Button
                text="Submit"
                onClick={submit}
                loading={loading}
                disabled={
                  invalidEmail || kybFormHasErrors || merchantFormHasErrors
                }
              />
            </div>
          </>
        )}
        {value && (
          <>
            <SendInPassportReminder documentEmail={documentEmail} />
            <Paragraph>
              When we get these files we will handle your application and will
              get back to you with questions (if there are any). We will also
              get back to you with the proposal.
            </Paragraph>
          </>
        )}
        <Snackbar
          message={[
            'Looks like something went wrong... :(',
            `${error?.reason}`,
            'Please contact support.',
          ]}
          variant="danger"
          open={showError}
          onClose={() => setShowError(false)}
        />
      </Section>
    </div>
  );
};

const useStyles = createStyles(({ spacing, palette }) => ({
  listItemBorder: {
    borderRadius: spacing.xxxs,
    background: palette.white,
    border: `1px solid ${palette.grey.x200}`,
    ':hover': {
      borderColor: palette.grey.x600,
    },
  },
}));
