import { Cookies } from 'react-cookie';
import { useMount } from 'react-use';
import { Locale } from 'types';
import { useCookies } from './useCookies';

export const PREFERENCES_COOKIE_NAME = 'qbPay_preferences';
const defaultLocale: Locale = 'en';

type UseLocaleReturnType = {
  locale: Locale;
  changeLocale: (locale: Locale) => void;
};

export const useLocale = (): UseLocaleReturnType => {
  const [preferencesCookie, setPreferencesCookie] = useCookies(
    PREFERENCES_COOKIE_NAME
  );

  useMount(() => {
    if (!preferencesCookie) {
      setPreferencesCookie({ locale: defaultLocale });
    }
  });

  const changeLocale = (locale: Locale) => {
    setPreferencesCookie({ ...preferencesCookie, locale });
  };
  return {
    locale: (preferencesCookie?.locale as Locale) || defaultLocale,
    changeLocale,
  };
};

export const getLocaleFromCookies = () => {
  const cookies = new Cookies();
  const preferencesCookie = cookies.get(PREFERENCES_COOKIE_NAME);

  if (!preferencesCookie || !preferencesCookie.locale) {
    return defaultLocale;
  }

  return preferencesCookie.locale as Locale;
};
