import { MoneyScaled, removeScaleFromMoney } from '@quickbit/qb-design-system';
import { useIntl } from 'react-intl';

export const useFormatCurrency = (): ((
  money: MoneyScaled,
  cryptoCurrency?: boolean
) => string) => {
  const { formatNumber, locale } = useIntl();
  return (money: MoneyScaled, cryptoCurrency?: boolean): string => {
    const amount = removeScaleFromMoney(money);
    if (cryptoCurrency)
      return `${formatNumber(removeScaleFromMoney(money), {
        minimumSignificantDigits: 3,
      })} ${money.currencyCode}`;
    if (money.currencyCode.length !== 3) {
      return locale === 'sv'
        ? `${amount} ${money.currencyCode}`
        : `${money.currencyCode} ${amount}`;
    }
    return formatNumber(removeScaleFromMoney(money), {
      style: 'currency',
      currency: money.currencyCode,
      minimumSignificantDigits: 3,
    });
  };
};
