/** @jsxImportSource @emotion/react */
import { keyframes } from '@emotion/react';
import { createStyles, Typography, useTheme } from '@quickbit/qb-design-system';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useMount } from 'react-use';
import { ErrorPage } from 'components';
import { useOrderId } from 'context';
import { usePoll } from 'hooks';
import { fetchOrderStatus, OrderStatusResponse } from 'pages/api';
import { OrderStatus } from 'types';

export const Refunding = () => {
  const { palette } = useTheme();
  const orderId = useOrderId();
  const navigate = useNavigate();
  const styles = useStyles();

  const checkStatus = useCallback(
    ({ status }: OrderStatusResponse) => {
      if (status === OrderStatus.REFUNDED) {
        navigate('/refunded');
        return true;
      }
      if (status === OrderStatus.CANCELLED) {
        navigate('/cancelled');
        return true;
      }
      if (
        status !== OrderStatus.REFUNDING &&
        status !== OrderStatus.REFUND_ADDRESS_SUBMITTED
      ) {
        return false;
      }
      return null;
    },
    [navigate]
  );

  const poll = useCallback(() => fetchOrderStatus(orderId), [orderId]);

  const { start, error } = usePoll({
    onPoll: checkStatus,
    poll,
  });

  useMount(() => start());

  if (error)
    return <ErrorPage error={error} tryAgainAction={() => navigate('/')} />;

  return (
    <>
      <Typography variant="subtitle">
        <FormattedMessage id="page.refunding.heading" />
      </Typography>
      <div css={styles.loaderContainer}>
        <div css={styles.loaderWrapper}>
          <span css={styles.loader} />
        </div>
      </div>
      <Typography variant="bodySmall" color={palette.grey.default}>
        <FormattedMessage id="page.refunding.subHeading" />
      </Typography>
    </>
  );
};

const useStyles = createStyles(({ palette, spacing, shape }) => ({
  loaderContainer: {
    margin: `${spacing.xxxs} 0`,
    width: '100%',
  },
  loaderWrapper: {
    width: '100%',
    background: palette.primary.x50,
    height: `calc(${spacing.xxxs} / 2)`,
    position: 'relative',
    overflow: 'hidden',
  },
  loader: {
    height: '100%',
    position: 'absolute',
    borderRadius: shape.borderRadius,
    animation: `3s cubic-bezier(0.65, 0.815, 0.735, 0.395) 0s infinite normal none running ${keyframes`
                0% {
                  left: -35%;
                  right: 100%;
                }
                60% {
                    left: 100%;
                    right: -90%;
                }
                100% {
                    left: 100%;
                    right: -90%;
                }
              `}`,
    background: palette.primary.default,
  },
}));
