import { Validators } from '@quickbit/qb-design-system';
import { Merchant } from '../types';

export const merchantValidators: Validators<Merchant, Merchant> = {
  name: (name: string) => (name.length < 1 ? 'Can not be empty.' : undefined),
  iban: (iban: string) => {
    if (iban.length < 1) return 'Can not be empty.';
    if (iban.length > 34) return 'Can not be longer than 34 characters.';
    return undefined;
  },
  bic: (bic: string) => {
    if (bic.length < 1) return 'Can not be empty.';
    if (bic.length > 11) return 'Can not be longer than 11 characters.';
    return undefined;
  },
  paymentReference: (reference: string) => {
    if (reference.length < 1) return 'Can not be empty.';
    if (reference.length > 255) return 'Can not be longer than 255 characters.';
    return undefined;
  },
};
