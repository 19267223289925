import { contentfulGraphql, Query } from './contentful';

export type ImageKey =
  | 'customerInformation'
  | 'learning'
  | 'thumbsUp'
  | 'appStore'
  | 'googlePlay'
  | 'appQRCode'
  | 'transferSuccess'
  | 'transferLoading'
  | 'emptyState'
  | 'bankId'
  | 'unplugged'
  | 'updateApp';

const imageQuery = `
query imageQuery($key: String!) {
  collection: imageCollection(where: { key: $key }) {
    items {
      key
      file {
          url(transform: {quality: 70})
      }
    }
  }
}
`;

export interface Image {
  key: ImageKey;
  file: { url: string };
}

export const getImage = async (key: ImageKey) => {
  const query: Query = {
    query: imageQuery,
    variables: { key },
  };

  const gqlResult = await contentfulGraphql<{
    collection: {
      items: Image[];
    };
  }>(query);

  const image = gqlResult.collection.items.at(0);
  if (image) return image;

  console.error(`Image with key ${key} not found`);
  return {
    key,
    file: { url: undefined },
  };
};
