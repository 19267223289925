export interface Merchant {
  merchantId?: string;
  name: string;
  countryCode: string | null;
  iban: string;
  bic: string;
  paymentReference: string;
}

export type BusinessSector =
  | 'Financial institution, remittance, currency exchange'
  | 'Public administration'
  | 'Hotel/Restaurant/Tourism/Rental services'
  | 'Marketing/Business development'
  | 'Transport/Logistic services'
  | 'Publishing'
  | 'Agriculture/Forestry'
  | 'Financial institution: Other'
  | 'Data/IT'
  | 'Construction/Infrastructure'
  | 'Law/Finance/Recruitment'
  | 'Real estate'
  | 'Religious community'
  | 'Investment-/Holding company'
  | 'Retail'
  | 'Healthcare'
  | 'Education/Communication'
  | 'Manufacture'
  | 'Charity/Fundraising'
  | 'Casino/Gaming industry'
  | 'Cryptocurrency business';

export const BUSINESS_SECTORS: Record<BusinessSector, true> = {
  'Financial institution, remittance, currency exchange': true,
  'Public administration': true,
  'Hotel/Restaurant/Tourism/Rental services': true,
  'Marketing/Business development': true,
  'Transport/Logistic services': true,
  Publishing: true,
  'Agriculture/Forestry': true,
  'Financial institution: Other': true,
  'Data/IT': true,
  'Construction/Infrastructure': true,
  'Law/Finance/Recruitment': true,
  'Real estate': true,
  'Religious community': true,
  'Investment-/Holding company': true,
  Retail: true,
  Healthcare: true,
  'Education/Communication': true,
  Manufacture: true,
  'Charity/Fundraising': true,
  'Casino/Gaming industry': true,
  'Cryptocurrency business': true,
};
export const BUSINESS_SECTOR_OPTIONS = Object.keys(BUSINESS_SECTORS).map(
  (k) => ({ label: k, value: k })
);

export interface ContactInformation {
  postalRegion: string;
  postalNumber: string;
  country: string;
  address1: string;
}

export interface RelatedPersonBase {
  id: string;
  firstName: string;
  lastName: string;
  isPep: boolean;
}

export interface RelatedPerson extends RelatedPersonBase {
  dateOfBirth: Date;
}

export interface RelatedPersonFormData extends RelatedPersonBase {
  dateOfBirth: Date | null;
  role: string;
  placeOfBirth: string;
  type: string;
  ssn: string;
}

export interface KybChange {
  changedBy: string;
  state: string;
  created: Date;
}
export interface KybBase {
  fullCompanyName: string;
  previousCompanyNames?: string;
  registrationNumber: string;
  registeredAddress: ContactInformation;
  primaryAddress: ContactInformation;
  businessSectors: BusinessSector[];
  expectedMonthlyVolume: number;
  licenseForBusiness?: string;
  jurisdictions: string;
  turnover: number;
  expectedMonthlyTransactions: number;
  usaConnection: boolean;
  businessOperations: string;
  authorizedSignatory: string;
}
export interface KybSave extends KybBase {
  incorporationDate: Date;
  incorporationCountry: string;
  relatedPeople: RelatedPerson[];
}

export interface KybRead extends KybSave {
  changelog: KybChange[];
  riskScore: number;
}
export interface KybFormData extends KybBase {
  incorporationDate: Date | null;
  incorporationCountry: string | null;
  relatedPeople: RelatedPersonFormData[];
}
