import { useState, createContext, useContext, ReactNode } from 'react';
import { OrderResponse as Order } from 'api/getOrder';

const SESSION_STORAGE_KEY = 'qbPay_order';

type OrderContext =
  | (Order & {
      setOrder: (order: Order) => void;
    })
  | {
      setOrder: (order: Order) => void;
    };

const getOrderFromLocalStorage = (): Order | null => {
  const order = sessionStorage.getItem(SESSION_STORAGE_KEY);
  if (!order) {
    return null;
  }
  return JSON.parse(order) as Order;
};

const Context = createContext<OrderContext>({
  ...getOrderFromLocalStorage(),
  setOrder: () => undefined,
});

export const OrderProvider = ({ children }: { children: ReactNode }) => {
  const [order, setOrderState] = useState<Order | null>(
    getOrderFromLocalStorage()
  );

  const setOrder = (order: Order) => {
    sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(order));
    setOrderState(order);
  };

  return (
    <Context.Provider value={{ ...order, setOrder }}>
      {children}
    </Context.Provider>
  );
};

export const useOrder = (): Order => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setOrder, ...rest } = useContext(Context);
  if (!('uuid' in rest))
    throw Error('Using order context before order is loaded.');
  return rest;
};

export const useNullableOrder = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setOrder, ...rest } = useContext(Context);
  if (!('uuid' in rest)) return null;
  return rest;
};

export const useSetOrder = () => useContext(Context).setOrder;
