/** @jsxImportSource @emotion/react */
import {
  createStyles,
  Icon,
  IconName,
  Typography,
} from '@quickbit/qb-design-system';
import { Dispatch, FunctionComponent } from 'react';

interface Props {
  title?: string;
  onBack?: () => void;
  showModal: Dispatch<boolean>;
  icon?: IconName;
  menu: boolean;
  setShowMenu: Dispatch<boolean>;
}

export const Header: FunctionComponent<Props> = ({
  title,
  showModal,
  icon,
  onBack,
  menu = false,
  setShowMenu,
}) => {
  const styles = useStyles();

  return (
    <header css={styles.header}>
      {onBack && (
        <div
          css={styles.back}
          onClick={() => {
            onBack && onBack();
          }}
        >
          <Icon name={'chevron'} />
        </div>
      )}
      {title && (
        <div css={styles.title}>
          <Typography variant="bodyBold">{title}</Typography>
        </div>
      )}
      <div css={styles.icon}>
        {icon && (
          <div
            onClick={() => {
              showModal(true);
              setShowMenu(false);
            }}
          >
            <Icon name={icon} />
          </div>
        )}
        {menu && (
          <div
            onClick={() => {
              setShowMenu(true);
              showModal(false);
            }}
          >
            <Icon name="hamburger" />
          </div>
        )}
      </div>
    </header>
  );
};

const useStyles = createStyles(({ spacing, palette }) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    minHeight: spacing.m,
    padding: `${spacing.xxxs} ${spacing.s}`,
    borderBottom: `1px solid ${palette.grey.x100}`,
    backgroundColor: palette.white,
    width: '100%',
  },
  title: {
    margin: '0 auto',
  },
  icon: {
    right: 0,
    position: 'absolute',
    display: 'flex',
    gap: spacing.xxxs,
    padding: spacing.xxxs,
    cursor: 'pointer',
  },
  back: {
    left: 0,
    position: 'absolute',
    padding: spacing.xxxs,
    cursor: 'pointer',
    svg: { fill: 'none', stroke: palette.black },
  },
}));
