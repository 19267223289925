/** @jsxImportSource @emotion/react */
import {
  Button,
  Icon,
  Input,
  removeScaleFromMoney,
  Typography,
  useTheme,
} from '@quickbit/qb-design-system';
import { ChangeEvent, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { ErrorPage } from 'components';
import { useOrderDetails } from 'hooks';
import { initiateRefund } from './api';

const contactSupport = () => {
  window.open('mailto:support@quickbit.com');
};

export const InvalidAmount = () => {
  const { palette, spacing } = useTheme();
  const { order, error } = useOrderDetails();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [address, setAddress] = useState('');

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setAddress(e.target.value);
    },
    [setAddress]
  );

  const handlePaste = useCallback(async () => {
    const pastedText = await navigator.clipboard.readText();
    setAddress(pastedText);
  }, [setAddress]);

  const [{ loading, error: refundError }, refund] = useAsyncFn(async () => {
    if (!order) return;
    await initiateRefund(order.order.uuid, address);
    navigate('/refunding');
  }, [order, address, initiateRefund]);

  const receivedAmount = order?.order.refund?.receivedAmount
    ? removeScaleFromMoney(order?.order.refund?.receivedAmount)
    : undefined;
  const receivedCurrency = order?.order.refund?.receivedAmount.currencyCode;

  if (error)
    return <ErrorPage error={error} tryAgainAction={() => navigate(0)} />;

  if (refundError)
    return <ErrorPage error={refundError} tryAgainAction={() => navigate(0)} />;

  return (
    <>
      <div css={{ marginBottom: spacing.xxxs }}>
        <Typography variant="subtitle">
          <FormattedMessage id="page.invalidAmount.heading" />
        </Typography>
      </div>
      <Typography variant="bodySmall" color={palette.grey.default}>
        <FormattedMessage
          id="page.invalidAmount.subHeading"
          values={{ amount: receivedAmount, currency: receivedCurrency }}
        />
      </Typography>
      <div css={{ marginTop: spacing.xs, marginBottom: spacing.xxxs }}>
        <Input
          value={address}
          onChange={handleChange}
          placeholder={formatMessage({
            id: 'page.invalidAmount.refundAddress',
          })}
          endAdornment={
            <div css={{ cursor: 'pointer' }} onClick={handlePaste}>
              <Icon name="paste" />
            </div>
          }
        />
      </div>
      <Typography variant="captionSmallMedium" color={palette.grey.default}>
        <FormattedMessage id="page.invalidAmount.caption" />
      </Typography>
      <div css={{ marginTop: spacing.xs, marginBottom: spacing.xxs }}>
        <Button
          loading={loading}
          text={formatMessage({ id: 'page.invalidAmount.submit' })}
          onClick={refund}
          disabled={address.length === 0}
        />
      </div>
      <Button
        variant="secondary"
        text={formatMessage({ id: 'page.invalidAmount.contactSupport' })}
        onClick={contactSupport}
      />
    </>
  );
};
