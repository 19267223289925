/** @jsxImportSource @emotion/react */
import { DesignSystemProvider } from '@quickbit/qb-design-system';
import { Fragment, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { Routes, Route, Outlet } from 'react-router-dom';
import { Layout } from 'components';
import { OrderIdProvider, OrderProvider } from 'context';
import copyContentJson from 'copy.json';
import { useLocale } from 'hooks';
import {
  Home,
  Success,
  Cancelled,
  NotFound,
  ProcessingInternal,
  ProcessingExternal,
  Docs,
  SignUp,
  InvalidAmount,
  Refunding,
  Refunded,
} from 'pages';
import { Locale } from 'types';
import { renderText } from 'utils/renderText';

const App = () => {
  const { locale } = useLocale();

  const content = useMemo(() => {
    const copyContent = copyContentJson as Record<
      string,
      Record<Locale, string>
    >;
    return Object.keys(copyContent).reduce(
      (prev, currentKey) => ({
        ...prev,
        [currentKey]: copyContent[currentKey][locale],
      }),
      {}
    );
  }, [locale]);

  return (
    <IntlProvider
      locale={locale}
      messages={content}
      textComponent={({ children }: { children?: string[] }) => {
        if (!children) return null;
        return (
          <Fragment>
            {renderText(Array.isArray(children) ? children[0] : children)}
          </Fragment>
        );
      }}
    >
      <DesignSystemProvider>
        <Routes>
          <Route path="/docs/*" element={<Docs />} />
          <Route path="/sign-up/*" element={<SignUp />} />
          <Route path="/not-found" element={<NotFound />} />
          <Route
            path="/"
            element={
              <OrderIdProvider>
                <OrderProvider>
                  <Layout>
                    <Outlet />
                  </Layout>
                </OrderProvider>
              </OrderIdProvider>
            }
          >
            <Route index element={<Home />} />
            <Route
              path="/processing-internal"
              element={<ProcessingInternal />}
            />
            <Route
              path="/processing-external"
              element={<ProcessingExternal />}
            />
            <Route path="/success" element={<Success />} />
            <Route path="/invalid-amount" element={<InvalidAmount />} />
            <Route path="/refunding" element={<Refunding />} />
            <Route path="/refunded" element={<Refunded />} />
            <Route path="/cancelled" element={<Cancelled />} />
          </Route>

          {/* catch all non existing routes */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </DesignSystemProvider>
    </IntlProvider>
  );
};

export default App;
