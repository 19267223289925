/** @jsxImportSource @emotion/react */

import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import { SHOW_PROCESSING } from '../constants';
import specs from '../openapi.json';
import specs_with_processing from '../openapi_with_processing.json';

export const Endpoints = () => {
  return (
    <SwaggerUI
      spec={SHOW_PROCESSING ? specs_with_processing : specs}
      supportedSubmitMethods={[]}
      defaultModelsExpandDepth={-1}
      defaultModelRendering={'model'}
    />
  );
};
