import { fetchAPI } from 'api';

export const initiateRefund = (
  orderId: string,
  refundDestinationAddress: string
) => {
  return fetchAPI(`v1/order/${orderId}/initiate-refund`, {
    method: 'POST',
    body: {
      refundDestinationAddress,
    },
  });
};
