import React, { ReactNode } from 'react';

export const renderText = (text: string) => {
  if (!text.includes('\n')) return text;
  // Replace new-lines inserted in Contentful using "shift + enter" with <br>.
  return text
    .split('\n')
    .reduce<ReactNode[]>((children, textSegment, index) => {
      return [
        ...children,
        ...(index > 0 ? [<br key={index} data-linebreak />] : []),
        textSegment,
      ];
    }, []);
};
