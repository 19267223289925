export const getOrderIdFromSearchParams = (): string | null => {
  const params = new URLSearchParams(window.location.search);
  // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
  const orderId = params.get('order-id'); //
  if (!orderId) {
    return null;
  }
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  const isValidUUID = regexExp.test(orderId);
  if (!isValidUUID) {
    throw Error('order-id param is not a valid UUID');
  }

  return orderId;
};
