/** @jsxImportSource @emotion/react */
import { createStyles } from '@quickbit/qb-design-system';
import { useIntl } from 'react-intl';
import { useMount } from 'react-use';
import { IllustrationWithText } from 'components';
import { useOrder } from 'context';

export const Refunded = () => {
  const { formatMessage } = useIntl();
  const styles = useStyles();
  const { failureRedirectUrl } = useOrder();

  useMount(() => {
    sessionStorage.clear();
    if (failureRedirectUrl) {
      setTimeout(() => {
        window.location.replace(failureRedirectUrl);
      }, 2000);
    }
  });

  return (
    <div css={styles.main}>
      <IllustrationWithText
        illustration="transferSuccess"
        heading={formatMessage({
          id: 'page.refunded.heading',
        })}
        subheading={
          failureRedirectUrl
            ? formatMessage({ id: 'redirect.backToMerchant' })
            : formatMessage({ id: 'page.cancelled.subHeading' })
        }
      />
    </div>
  );
};

const useStyles = createStyles(() => ({
  main: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
}));
