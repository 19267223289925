import { Typography } from '@quickbit/qb-design-system';
import { Link } from 'react-router-dom';
import { SHOW_PROCESSING } from '../constants';
import { InlineCode } from './InlineCode';

export const GetStarted = () => {
  return (
    <>
      <Typography variant="heading1">Quickbit Pay</Typography>
      <Typography variant="body">
        Quickbit Pay is a sophisticated crypto payment gateway that enables
        merchants to accept crypto as a means of payment, and the customer to
        use their crypto in order to pay for goods and services.
      </Typography>
      <br></br>
      <Typography variant="body">
        Below you will find documentation describing cryptocurrencies as well as
        the underlying technology, the blockchain. You will also find what
        Quickbit Pay offers and how this payment gateway can be the perfect fit
        for you and your product offering.
      </Typography>
      <br></br>
      <Typography variant="body">
        For more information about how to integrate with the API, go to{' '}
        <Link to="/docs/integration">Integration</Link>, and for the API
        specification go to <Link to="/docs/endpoints">Endpoints</Link>. For
        information about the Quickbit Pay backoffice, go to{' '}
        <Link to="/docs/backoffice">Backoffice</Link>.
      </Typography>
      <br></br>
      <Typography variant="heading2">
        Cryptocurrencies & the blockchain
      </Typography>
      <Typography variant="body">
        Similarly to government-issued fiat currencies (EUR or USD, for
        instance), cryptocurrencies can be used as a monetary instrument to
        purchase goods and services. Cryptocurrencies may differ in how they
        work technically, but each has a value and can be exchanged into other
        currencies. Cryptocurrencies can &quot;live&quot; on different
        blockchains that do not interact with each other by design. Therefore a
        payment gateway such as Quickbit Pay can help with bridging these gaps.
        For instance, Ethereum and Bitcoin (the two largest cryptocurrencies by
        market cap) do not interact with each other. If you as a merchant have a
        Bitcoin wallet you would not be able to accept a customer that wants to
        buy with Ethereum unless you also have an Ethereum wallet, which can
        become an arduous and manual process. Quickbit Pay solves this by
        funneling multiple cryptocurrencies into one settlement.
      </Typography>
      <br></br>
      <Typography variant="body">
        When crypto is sent, a transaction between wallets is added to the
        blockchain. Since the blockchain is a decentralized ledger, a
        transaction is valid if multiple so called nodes in the
        cryptocurrency&apos;s network validate and confirm this transaction.
        Once the network has acknowledged the transaction, the money is
        considered to be sent between the wallets. Quickbit Pay scans the
        blockchains to determine that the money for your orders are sent to the
        correct wallets.
      </Typography>
      <br></br>
      <Typography variant="body">
        Another alternative to sending on the blockchain is custodial solutions.
        This is where end users do not control their addresses directly but
        instead let companies handle them. This allows for instantaneous
        transactions within the companies&apos; ecosystems (but still requires
        the blockchain between custodial solutions). Quickbit manages a
        custodial solution, meaning that customers with Quickbit wallets can
        send money to pay for your goods or services instantly.
      </Typography>
      <br></br>
      <Typography variant="heading2">What is Quickbit Pay?</Typography>
      <Typography variant="body">
        Quickbit Pay is, as briefly described above, a technical solution
        allowing merchants to sell products for crypto. Using Quickbit Pay, you
        will be able to process orders from customers that wish to pay with
        crypto and thereby widen your customer base with the people that use
        crypto as a means of payments.
      </Typography>
      <br></br>
      <Typography variant="body">
        When using Quickbit Pay you create orders using our API and then get a
        checkout page to host on your checkout site. The checkout page is user
        friendly so your users can feel comfortable using this payment method
        for their purchases.
      </Typography>
      <br></br>
      <Typography variant="body">
        Quickbit Pay can from a customer&apos;s perspective be used in two ways.
        First, a customer could use it with any working crypto wallet by sending
        crypto to the wallet address created for the order. Second, they can use
        the Quickbit app and enjoy zero transaction fees, faster confirmation
        and a smoother user experience.
      </Typography>
      <br></br>
      <Typography variant="body">
        Functionality for processing payouts is forthcoming.
      </Typography>
      <br></br>
      <Typography variant="heading2">How do you get started?</Typography>
      <Typography variant="body">
        To get started using Quickbit Pay, contact{' '}
        <a href="mailto:sales@quickbit.com">sales@quickbit.com</a>. After your
        application has been approved by our onboarding team, you will receive a
        secret API key to be used for integrating with our software. You can
        read more about this on the{' '}
        <Link to="/docs/integration">Integration</Link> page.
      </Typography>
      <br></br>
      <div id="settlement"></div>
      <Typography variant="heading2">Settlement</Typography>
      <Typography variant="body">
        Quickbit Pay has <InlineCode>T+3</InlineCode> settlement. This means
        that completed orders from each day will be settled within three bank
        days of completion. Quickbit Pay will transfer your money to the bank
        account specified during the onboarding process, and will settle these
        orders in EUR.
      </Typography>
      <br></br>
      <Typography variant="heading2">Migration flow</Typography>
      <Typography variant="body">
        If you have a low conversion rate through Quickbit Pay or want to boost
        your crypto payment traffic and volume you can implement a Migration
        flow that allows users to pay for orders using Quickbit Pay and buy
        crypto in the same flow. This is done by creating orders with a specific
        flag set that brings users to the Quickbit App instead of a Quickbit Pay
        checkout page. More information can be found in the&nbsp;
        <Link to="/docs/integration/migration-flow">
          Migration Flow
        </Link> and <Link to="/docs/endpoints">Endpoints</Link> pages.
      </Typography>
      <br></br>
      {SHOW_PROCESSING && (
        <>
          <Typography variant="heading2">Processing flow</Typography>
          <Typography variant="body">
            It is possible to pay for orders through Quickbit without any user
            interaction needed. This is done through the Processing Flow, by
            creating orders with a specific flag set that causes orders to be
            handled completely within the Quickbit system, while still sending
            crypto to a unique user&apos;s address. More information can be
            found in the{' '}
            <Link to="/docs/integration/processing-flow">Processing Flow</Link>{' '}
            and <Link to="/docs/endpoints">Endpoints</Link> pages.
          </Typography>
          <br></br>
        </>
      )}
      <Typography variant="heading2">Platform integration</Typography>
      <Typography variant="body">
        Quickbit Pay is a payment solution intended for business-to-consumer
        companies. For business-to-business companies, i.e. where your business
        provides a platform for other companies that need to accept payments,
        Quickbit offers the platform integration. This integration enables your
        business to act as a single point of entry for your business partners to
        use the Quickbit Pay payment solution. More information can be found in
        the <Link to="/docs/integration/platforms">Platform integration</Link>{' '}
        and <Link to="/docs/endpoints">Endpoints</Link> pages.
      </Typography>
      <br></br>
      <div id="tolerance"></div>
      <Typography variant="heading2">Tolerance</Typography>
      <Typography variant="body">
        Quickbit Pay expects an order to be paid with exactly the correct
        amount. But because of network fees, different cryptocurrency wallets,
        exchange platforms and exchange rates, there is a significant risk that
        the customer will not enter exactly the correct amount. Quickbit Pay
        tries to be as clear as possible about what amount should be sent in
        order to reduce confusion and minimize the risk of a failed payment, but
        to further increase the chance of successful payments you can use the
        Tolerance feature.
      </Typography>
      <br></br>
      <Typography variant="body">
        With the Tolerance feature you can accept payments that are not exactly
        equal to the expected amount. As discussed above, this can reduce the
        number of failed payments due to small deviations from the expected
        amount. If the customer is not buying a product with a fixed price, but
        instead is, for example, depositing funds into an account at a betting
        site, then it can make sense to accept large deviations from the
        expected amount, because you can deposit funds based on what the
        customer actually sent. You, the merchant, define the bounds, for
        example 80% as the lower bound, and 120% as the upper bound. If the
        customer&apos;s payment is within the bounds relative to the expected
        amount then the payment is considered successful. You can find out how
        much the customer paid using the&nbsp;
        <InlineCode>{'GET /api/pay/v1/order/{order_id}'}</InlineCode> endpoint.
        It will include a field in the response called{' '}
        <InlineCode>received_amount</InlineCode> which is the amount that the
        customer sent minus the customer fee, exchanged into the currency in the{' '}
        <InlineCode>currency</InlineCode> field. In other words, this is the
        amount that the customer should be credited with, and it would be equal
        to <InlineCode>creation_amount</InlineCode> if the customer sent the
        expected amount.
      </Typography>
      <br></br>
      <Typography variant="body">
        The bounds can be defined in the backoffice. Note that if you enable
        Tolerance by setting bounds in the backoffice, it will be enabled, and
        the bounds will be the same, for all orders. Remember this, since
        accepting large deviations when selling a product with fixed price will
        pose a problem, because any payment within the bounds will be considered
        successful.
      </Typography>
      <br></br>
      <Typography variant="heading2">Questions</Typography>
      <Typography variant="body">
        If you have any questions regarding Quickbit Pay, please reach out to{' '}
        <a href="mailto:sales@quickbit.com">sales@quickbit.com</a> so we can
        discuss how Quickbit Pay can be used for your product offering.
      </Typography>
    </>
  );
};
