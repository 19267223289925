import type { Validators } from '@quickbit/qb-design-system';
import { KybFormData } from '../types';

export const kybValidators: Validators<KybFormData, KybFormData> = {
  fullCompanyName: (name: string) =>
    name.length < 1 ? 'Can not be empty.' : undefined,
  registrationNumber: (number: string, currentValues: KybFormData) => {
    if (currentValues.incorporationCountry === 'SE') {
      return !/[\d]{6}-[\d]{4}/.test(number)
        ? 'Needs to be on the format XXXXXX-XXXX.'
        : undefined;
    }
    return number.length < 1 ? 'Can not be empty.' : undefined;
  },
  incorporationDate: (date: Date | null) => {
    if (date === null) return 'This needs to be set.';
    if (date > new Date()) return 'Cannot be in the future.';
    return undefined;
  },
  incorporationCountry: (country: string | undefined) =>
    !country ? 'This needs to be set.' : undefined,
  expectedMonthlyVolume: (volume: number) =>
    volume < 0 ? 'Can not have negative values.' : undefined,
  expectedMonthlyTransactions: (pay: number) =>
    pay < 0 ? 'Can not have negative values.' : undefined,
  turnover: (turnover: number) =>
    turnover < 0 ? 'Can not have negative values.' : undefined,
  jurisdictions: (jurisdictions: string) =>
    jurisdictions.length < 1 ? 'Can not be empty.' : undefined,
  authorizedSignatory: (value: string) =>
    value.length < 1 ? 'Can not be empty.' : undefined,
  registeredAddress: {
    address1: (address: string) =>
      address.length < 1 ? 'Can not be empty.' : undefined,
    country: (country: string) =>
      country.length < 1 ? 'Can not be empty.' : undefined,
    postalNumber: (postalNumber: string) =>
      postalNumber.length < 1 ? 'Can not be empty.' : undefined,
    postalRegion: (postalRegion: string) =>
      postalRegion.length < 1 ? 'Can not be empty.' : undefined,
  },
  primaryAddress: {
    address1: (address: string) =>
      address.length < 1 ? 'Can not be empty.' : undefined,
    country: (country: string) =>
      country.length < 1 ? 'Can not be empty.' : undefined,
    postalNumber: (postalNumber: string) =>
      postalNumber.length < 1 ? 'Can not be empty.' : undefined,
    postalRegion: (postalRegion: string) =>
      postalRegion.length < 1 ? 'Can not be empty.' : undefined,
  },
  relatedPeople: {
    firstName: (firstName: string) =>
      firstName.length < 1 ? 'Can not be empty.' : undefined,
    lastName: (lastName: string) =>
      lastName.length < 1 ? 'Can not be empty.' : undefined,
    ssn: (ssn: string) => (ssn.length < 1 ? 'Can not be empty.' : undefined),
    dateOfBirth: (date: Date | null) => {
      if (date === null) return 'Needs to be set.';
      if (date > new Date()) return 'Cannot be in the future.';
      return undefined;
    },
    type: (type: string | undefined) =>
      !type ? 'This needs to be set.' : undefined,
  },
};
