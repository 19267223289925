/** @jsxImportSource @emotion/react */
import {
  Card,
  Errors,
  Input,
  Select,
  Setters,
  Typography,
  useTheme,
} from '@quickbit/qb-design-system';
import { ContactInformation } from 'pages/SignUp/types';
import { COUNTRY_OPTIONS } from 'utils';

interface Props {
  label: string;
  data: ContactInformation;
  setters: Setters<ContactInformation>;
  editable: boolean;
  errors: Errors<ContactInformation>;
  onBlur?: () => void;
}

export const ContactInformationForm = ({
  label,
  data,
  setters,
  editable,
  errors,
  onBlur,
}: Props) => {
  const { spacing } = useTheme();
  const { address1, country, postalNumber, postalRegion } = data;
  return (
    <Card>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: spacing.xs,
        }}
      >
        <Typography variant="bodyBold">{label}</Typography>
      </div>
      <div
        css={{
          display: 'grid',
          gap: spacing.xs,
        }}
      >
        <Input
          label="Address"
          value={address1}
          disabled={!editable}
          onChange={(e) => setters.address1.set(e.target.value)}
          onBlur={onBlur}
          error={!!errors.address1}
          helperText={errors.address1}
        />
        <Input
          label="Postal number"
          value={postalNumber}
          disabled={!editable}
          onChange={(e) => setters.postalNumber.set(e.target.value)}
          onBlur={onBlur}
          error={!!errors.postalNumber}
          helperText={errors.postalNumber}
        />
        <Input
          label="Postal Region"
          value={postalRegion}
          disabled={!editable}
          onChange={(e) => setters.postalRegion.set(e.target.value)}
          onBlur={onBlur}
          error={!!errors.postalRegion}
          helperText={errors.postalRegion}
        />

        <Select
          label="Country"
          options={COUNTRY_OPTIONS}
          values={[country]}
          disabled={!editable}
          error={!!errors.country}
          helperText={errors.country}
          onSelect={([selected]) => setters.country.set(selected)}
        />
      </div>
    </Card>
  );
};
