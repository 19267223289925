import { useCallback, useState } from 'react';
import { useAsyncFn, useInterval } from 'react-use';
import { ErrorResponse } from 'api/fetchAPI';

const POLLING_INTERVAL = 5000;

interface PollConfig<T> {
  poll: () => Promise<T>;
  onPoll: (data: T) => boolean | null;
  interval?: number;
}

export const usePoll = <T>({
  poll,
  onPoll,
  interval = POLLING_INTERVAL,
}: PollConfig<T>) => {
  const [polling, setPolling] = useState<number | null>(null);

  const [{ error }, getStatus] = useAsyncFn(async () => {
    const data = await poll();
    const status = onPoll(data);
    if (status === null) return;
    if (status) {
      setPolling(null);
    } else {
      setPolling(null);
      throw {
        errorCode: 'POLL_FAILURE',
        reason: 'Unexpected order status',
      } as ErrorResponse;
    }
  }, [poll, onPoll, setPolling]);

  const startPolling = useCallback(() => {
    setPolling(interval);
  }, [setPolling, interval]);

  useInterval(() => {
    getStatus();
  }, polling);

  return {
    start: startPolling,
    error,
    isPolling: polling !== null,
  };
};
