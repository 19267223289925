/** @jsxImportSource @emotion/react */

import { Icon, useTheme } from '@quickbit/qb-design-system';

export const HelperText = ({
  error,
  text,
}: {
  error?: boolean;
  text: string;
}) => {
  const { spacing, typography } = useTheme();
  return (
    <span
      css={{
        display: 'flex',
        marginLeft: 0,
        alignItems: 'center',
      }}
    >
      <Icon
        name={error ? 'error' : 'info'}
        size={spacing.xxs}
        className={{ display: 'flex' }}
      />
      <small css={{ marginLeft: `${spacing.xxxs}`, ...typography.caption }}>
        {text}
      </small>
    </span>
  );
};
