/** @jsxImportSource @emotion/react */
import {
  ListItem,
  DateInput as DSDateInput,
  useTheme,
} from '@quickbit/qb-design-system';
import { HelperText } from './HelperText';

interface Props {
  title: string;
  value: Date | null;
  onChange: (d: Date) => void;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
}

export const DateInput = ({
  title,
  value,
  disabled,
  onChange,
  helperText,
  error,
}: Props) => {
  const { spacing, palette } = useTheme();
  return (
    <div css={{ li: { padding: `${spacing.xxxs} ${spacing.xxs}` } }}>
      <ListItem
        css={{
          borderRadius: spacing.xxxs,
          border: `1px solid ${palette.grey.x200}`,
          background: palette.white,
          ':hover': {
            borderColor: palette.grey.x600,
          },
        }}
        title={title}
        disabled={disabled}
        action={
          <DSDateInput
            type="default"
            value={value}
            onChange={onChange}
            placeholder="Select date"
          />
        }
      />
      {helperText && (
        <div css={{ marginTop: spacing.xxxs }}>
          <HelperText text={helperText} error={error} />
        </div>
      )}
    </div>
  );
};
