/** @jsxImportSource @emotion/react */
import { Typography, useTheme, createStyles } from '@quickbit/qb-design-system';

interface Props {
  text: string;
}

export const LineWithText = ({ text }: Props) => {
  const { spacing, palette, sizing, breakpoints } = useTheme();
  const styles = useStyles();
  return (
    <div css={{ display: 'flex', justifyContent: 'center' }}>
      <div
        css={{
          display: 'flex',
          width: '100%',
          maxWidth: sizing.medium,
          margin: 'auto',
          [breakpoints.laptopSmall]: {
            paddingLeft: spacing.m,
            paddingRight: spacing.m,
          },
        }}
      >
        <hr css={styles.hr} />
        <div css={{ flex: 1, textAlign: 'center' }}>
          <Typography variant="bodySmall" color={palette.grey.default}>
            {text}
          </Typography>
        </div>
        <hr css={styles.hr} />
      </div>
    </div>
  );
};

const useStyles = createStyles(({ palette }) => ({
  hr: { flex: 3, background: palette.grey.x300, border: 'none', height: '2px' },
}));
