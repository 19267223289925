import { useTheme } from '@quickbit/qb-design-system';
import { Prism } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

interface Props {
  language: string;
  code: string;
}

export const CodeBlock = ({ language, code }: Props) => {
  const { shape } = useTheme();
  return (
    <Prism
      language={language}
      style={materialDark}
      customStyle={{ borderRadius: shape.borderRadius }}
    >
      {code}
    </Prism>
  );
};
