/** @jsxImportSource @emotion/react */
import { Typography, useTheme } from '@quickbit/qb-design-system';
import { ReactNode } from 'react';

interface Props {
  title: string;
  children?: ReactNode;
}

export const Section = ({ title, children }: Props) => {
  const { spacing, breakpoints, sizing } = useTheme();
  return (
    <div
      css={{
        padding: `${spacing.m}`,
        [breakpoints.laptopSmall]: { padding: `${spacing.m} ${spacing.xl}` },
      }}
    >
      <div
        css={{
          [breakpoints.laptopSmall]: { width: `calc(1.5*${sizing.large})` },
        }}
      >
        <div>
          <Typography variant="heading1">{title}</Typography>
          <div
            css={{
              padding: `${spacing.s} 0`,
              display: `flex`,
              'flex-direction': `column`,
              gap: `${spacing.s}`,
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
